import { Auth0DecodedHash, WebAuth } from 'auth0-js';
import React from 'react'
import { Observable, map,concatAll, Subscriber, Subject, ReplaySubject, switchMap,share,debounceTime,catchError } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { getAuth0 } from '../utils/auth0'
import { IAuthContext, IAuthLoginResponse, IAuthResponse, IEnrollResponse, IEnrollRequest} from './AuthContext.d'
import * as Sentry from "@sentry/react";


const silentAuthAlternative = (subscriber:Subject<IAuthResponse>, webAuth:WebAuth)=> {
  try{
    const checkSessionParams:Record<string,string> = {}
    const token = localStorage.getItem("accessToken")
    if(token){
      checkSessionParams['accessToken'] = token
      checkSessionParams['responseType'] = "id_token token"
      checkSessionParams['audience'] = process.env.REACT_APP_AUTH0_AUDIENCE || ''
      checkSessionParams['scope'] ='openid profile email'
    }
    webAuth.checkSession(checkSessionParams,processResponse(subscriber,webAuth))
  }catch(err){    
    Sentry.addBreadcrumb({
      category: "auth",
      message: "Silent authentication fail by error",
      level: Sentry.Severity.Info,
    });
    Sentry.captureException(err)
    subscriber.next({authenticated:false})
    subscriber.complete()
  }
}

const processResponse=(subscriber:Subject<IAuthResponse>, webAuth:WebAuth, alternative?:Function)=> (err:any,user:any)=>{
  if(err){    
    Sentry.addBreadcrumb({
      category: "auth",
      message: "Authentication fail by error",
      level: Sentry.Severity.Info,
    });
    //If error then is not authenticated    
    subscriber.next({authenticated: false})        
    subscriber.complete()
  }else if(user?.accessToken){        
    let username =user.idTokenPayload?.email
    const sub = user.idTokenPayload?.sub
    if(username && username.indexOf("@anonymouscarbonaut.com")>=0){
      window.location.href = "https://calculatornopii.thecarbonauts.com/"
      return 
    }
    if(username){
      Sentry.setUser({ email: username });    
      // @ts-ignore
      window.FS.identify(sub, {email:username});
    }
    if(sub.indexOf("saml")>=0){      
      Sentry.setUser({ id: sub });
      // @ts-ignore
      window.FS.identify(sub);
    }
    //If authenticated    
    localStorage.setItem("accessToken", user.accessToken)
    localStorage.setItem("jwtToken", user.idToken)    
    //localStorage.removeItem("calculator:params")
    subscriber.next({authenticated: true})
    subscriber.complete()
  }else if(alternative){
    alternative(subscriber, webAuth)
  }else{
    Sentry.addBreadcrumb({
      category: "auth",
      message: "Authentication fail",
      level: Sentry.Severity.Info,
    });
    subscriber.next({authenticated: false})
    subscriber.complete()
  }
}

const doEnroll = (request:IEnrollRequest)=>{
  const token = localStorage.getItem('jwtToken')

  return ajax<IEnrollResponse>({
    url: `${process.env.REACT_APP_API_BASE_URL}/footprint/thinkific/enroll/${request.pod}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  })
}

const authenticate$ =new ReplaySubject<IAuthResponse>()
const enrollManager$ = new Subject<IEnrollRequest>()

export const initialAuth:IAuthContext = {
  login: ($events) => $events.pipe(map(({username, password}) => new Observable<IAuthLoginResponse>((subscriber)=> {
    const webAuth = getAuth0()
    webAuth.login({
      username, 
      password,
      audience:process.env.REACT_APP_AUTH0_AUDIENCE,
      realm: process.env.REACT_APP_AUTH0_REALM,
      responseType: 'id_token token'
    },(err, user)=>{      
      if(err){ 
        Sentry.captureException(err)
        Sentry.addBreadcrumb({
          category: "auth",
          message: "Authentication fail by error "+err.description,
          level: Sentry.Severity.Info,
        });       
        subscriber.next({error:err.description})
        subscriber.complete()
    }else{        
        Sentry.setUser({ email: username });
        // @ts-ignore
        window.FS.identify(user.idTokenPayload.sub, {email:username});
        subscriber.next()        
        subscriber.complete()
      }
    })
  })), concatAll()),
  requestAuthenticate: ()=>{
    const webAuth = getAuth0()    
    //Check authorization code if there is no code, try silent authentication
    webAuth.parseHash({ hash: window.location.hash, __enableIdPInitiatedLogin: true}, processResponse(authenticate$, webAuth, silentAuthAlternative))                    
  },  
  enroll: enrollManager$.pipe(
    debounceTime(100),
    switchMap(doEnroll),
    map((r)=> r.response),  
    share(),
    catchError( (error,caught) => caught),

  ),
  requestEnroll: (request)=> {
    enrollManager$.next(request)
  },
  authenticate: authenticate$,
  silentAuthenticate: (response: Subject<IAuthResponse> )=>{
    const webAuth = getAuth0();
    silentAuthAlternative( response, webAuth);    
  },
  signup: ($events) => $events.pipe(map( ({email, firstName, lastName, password,pod, photo}) => {    
    const formData = new FormData();
    formData.append('email', email);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('password', password);
    formData.append('pod', pod);
    if(photo){
      formData.append('avatar', photo);
    }
    return ajax<IAuthLoginResponse>({
      url: `${process.env.REACT_APP_API_BASE_URL}/footprint/signup`,
      method: 'POST',
      body: formData
    })    
  }), concatAll()).pipe(map(response=> response.response))
  ,logout: ()=> {
    Sentry.addBreadcrumb({
      category: "auth",
      message: "Logout",
      level: Sentry.Severity.Info,
    });
    const webAuth = getAuth0() 
    localStorage.removeItem("jwtToken")
    localStorage.removeItem("accessToken")    
    localStorage.removeItem("calculator:params")
    webAuth.logout({
      returnTo: process.env.REACT_APP_RETURN,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID
    });        
  },
  forgotPassword: ($events) => $events.pipe(map( (  {username}) => {    
    const webAuth = getAuth0()    
    const result = new Subject<boolean>()
    webAuth.changePassword({
      connection: process.env.REACT_APP_AUTH0_REALM||"",
      email: username
    }, function(err){
      if(err){
        result.next(false)
      }else{
        result.next(true)
      }
    });
    return result;
  }), concatAll())
};


const AuthContext = React.createContext<IAuthContext>(initialAuth);
export default AuthContext;
