import { FormControl, InputAdornment, OutlinedInput } from "@material-ui/core";
import { Fragment } from "react";
import NumberFormat from "react-number-format";
import { MILES_TO_KM, UNITS } from "../constant/questions";
import { IQuestionMultiple, IQuestionFields, IQuestionField } from "../context/CalculatorContext.d";
import HintDefault from "./HintDefault";
import './QuestionMultiple.css'
import Tooltip from "./Tooltip";
import { useTranslation } from 'react-i18next';

interface IQuestionMultipleProps{
  question: IQuestionFields
  value?: any
  onChange: (value:any)=>void
  units?: UNITS,
  default?: any
  needConversionDefault?: boolean
}
export default function QuestionMultiple(props:IQuestionMultipleProps){
  const {t} = useTranslation();

  const {question} = props;
  const handleChange = (newValue:string, field:IQuestionField)=>{
    let newValues:Record<string,any> = {}
    if (props.value){
      newValues = { ...props.value}
    }
    newValues[field.key] = newValue
    props.onChange(newValues)
  }
  const getValue = (key:string)=>{
    if(props.value){
      return props.value[key] || ""
    }
    return ""
  }
  const getPlaceholder = ( (field:IQuestionField) =>{
    if (field.helperPlaceholderUnit && props.units){
      return t(`questions:${field.helperPlaceholderUnit[props.units]}`)
    }
    return t(`questions:${field.helperPlaceholder}`)
  })
  const handleDefault = (field:IQuestionField)=>{
    if(props.default && props.default[field.key]){
      
      let newValues:Record<string,any> = {}
      if (props.value){
        newValues = { ...props.value}
      }      
      if(props.needConversionDefault){
        if(props.units == UNITS.METRIC){
          newValues[field.key] = (Number(props.default[field.key]) * MILES_TO_KM[UNITS.METRIC]).toFixed(2)
        }else{
          newValues[field.key] = Number(props.default[field.key]).toFixed(2)
        }        
      }else{
        newValues[field.key] = Number(props.default[field.key]).toFixed(2)
      }
      props.onChange(newValues)
    }
  }
  return (
    <div className="question-multiple">
      {question.fields.map(field=>        
        <FormControl className="question">
          <label>
            {t(`questions:${field.label}`)}
          </label>
          <div className="question-control">

            <NumberFormat 
        
              value={getValue(field.key)}
              customInput={OutlinedInput}
              onValueChange={({value:v}) => handleChange(v, field)} 
              placeholder={t(`questions:${field.placeholder}`)}
              inputProps={{min:0}}
              thousandSeparator={true}
              endAdornment={<InputAdornment position="end">{getPlaceholder(field)}</InputAdornment>}              
            />&nbsp;
            {question.tooltips && question.tooltips[`${question.slug}_${field.key}`] && 
              <Tooltip id={`tooltip-${field.key}`} title={question.tooltips[`${question.slug}_${field.key}`].title} text={question.tooltips[`${question.slug}_${field.key}`].text}/>
            }
          </div>
          <HintDefault onClick={()=> handleDefault(field)} />
        </FormControl>  
      )}
    </div>
  )
}