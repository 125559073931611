import React from 'react'
import { Observable, map, concatAll,Subject,share, catchError, of, retry } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import * as Sentry from "@sentry/react";
import { tokenObservable$ } from '../utils/observable';

const fetchUser = ()=>{
  const token = localStorage.getItem('jwtToken')  
  return ajax<IUser>({
    url: `${process.env.REACT_APP_API_BASE_URL}/footprint/me`,
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }   
  })
}

const user$ = new Subject<void>()

const initialUser:IUserContext={
  user: user$.pipe(
    map(fetchUser),
    retry(3),
    concatAll(),
    map(response=> response.response),
    catchError((error,caught) => {
      Sentry.captureException(error);
      return caught
    }),    
    share()
  ),
  requestUser:()=>{
    const token = localStorage.getItem('jwtToken')  
    if(token){
      user$.next()
    }
  }
}

export interface IUser{
  avatar?: string
  first_name: string
  last_name?: string
}

export interface IUserContext{
  user: Observable<IUser>,
  requestUser: ()=>void
}

const UserContext = React.createContext<IUserContext>(initialUser);

export default UserContext;
export {initialUser}