import { StepIconProps } from "@material-ui/core";
import { Check } from "@material-ui/icons";

const StepIcon = (props:StepIconProps)=> {
  const { active, completed, className, icon } = props;
  
  return (
    <div className={`${className?className:''} ${active?'active':''} ${completed?'completed':''}`}>
      {!completed && (
        <div className="circle">
          {icon}
        </div>
      )}
      {!active && completed &&(
        <Check />
      )}
    </div>
  );
}
export default StepIcon;