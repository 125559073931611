import { useObservableState } from "observable-hooks"
import React, { Children, useEffect, useRef, useState } from "react"
import { useContext } from "react"
// @ts-ignore
import Odometer from 'react-odometerjs';
import CalculatorContext from "../context/CalculatorContext"
import {Sunburst,SunburstPoint} from 'react-vis';
import './FootprintGraph.css'
import FootprintScoreGraph from "./FootprintScoreGraph";
import Tooltip from "./Tooltip";
import SectionSelection from "./SectionSelection";
import { MAP_LABELS } from "../constant/questions";
import { Popover, PopoverPosition } from "@material-ui/core";
import { useTranslation } from 'react-i18next';


let willCancel:NodeJS.Timeout|null = null

const safePercent= (tons?:number,total?:number)=>{
  if(tons && total){
    return ((tons/total)*100).toFixed(1)
  }
  return "0.0"
}

type ITransformHorizontal = "left"|"right"
type ITransformVertical = "top"|"bottom"|"center"
type IPositionWithTransform = {
  position: PopoverPosition,
  transformHorizontal: ITransformHorizontal,
  transformVertical: ITransformVertical,
}
export default function FootprintGraph(){  
  const {t} = useTranslation();

  const calculatorContext = useContext(CalculatorContext) 
  const tooltips = useObservableState(calculatorContext.tooltips$)  
  const calculation = useObservableState(calculatorContext.calculation$, calculatorContext.getPreviousCalculation())  
  const selected = useObservableState(calculatorContext.selectedCategories$, {})
  const [hovered, setHovered] = useState<SunburstPoint|null>(null);
  const [anchorPopover, setAnchorPopover] = useState<PopoverPosition>({top:100, left:0});
  const [transformHorizontal, setTransformHorizontal] = useState<ITransformHorizontal>("left");
  const [transformVertical, setTransformVertical] = useState<ITransformVertical>("center");
  const anchorEl = useRef<HTMLDivElement>(null)
  const [data, setData] = useState<SunburstPoint>({
    "title": "",    
    "children": calculation?.graph || []
  })
  //const score = hovered && hovered.value ? hovered.value  : (calculation?.total || 0)
  //const label = hovered && hovered.title ? hovered.title : undefined;
  const score = (calculation?.total || 0)
  const label = undefined;
  const filterData = (data:SunburstPoint)=>{
    return {
      title:"",
      children: data.children?.filter(d=> {
        const key = d.section
        return selected[key] === undefined ? true : selected[key]
      })
    }
  }
  const updateData =(node?:SunburstPoint)=> {
    const newChildren = data.children?.map(c =>{
      let opacity= 1
      if(node && c.title!=node.title){        
        opacity= 0.2        
      }      
      return {
        ...c,
        style:{
          fillOpacity: opacity
        }
      }
    })
    setData({
      "title":"",
      "children": newChildren || []
    })
  }
  const buildValue=(hoveredCell:SunburstPoint):IPositionWithTransform =>{
    const {radius, angle, angle0} = hoveredCell;
    const truedAngle = (angle + angle0) / 2;
    if(anchorEl.current){
      const Y = anchorEl.current.offsetTop + 130
      const X = anchorEl.current.offsetLeft + 130    
      const deltaX = (radius * Math.cos(truedAngle))
      const deltaY = (radius * Math.sin(truedAngle))  
      //console.log(deltaX,deltaY)
      return {
        position:{
          left: X + deltaX,
          top: Y - deltaY
        },
        transformHorizontal: deltaX> 0 ?"left":"right",
        transformVertical: "center"//deltaY< 0 ?"top":"bottom",
      }
    }
    return {
      position:{
        left: 0,
        top: 0
      },
      transformHorizontal: "left",
      transformVertical: "center",
    };
  }
  //const personal = hovered ? undefined:calculation?.totalPersonal || 0
  const personal = calculation?.totalPersonal   
  return (  
    <>
      <FootprintScoreGraph  label={label} score={score}/>
      <div ref={anchorEl} className="mask-graph" ></div>                            
      <Sunburst          
      className="footprint"
      hideRootNode
      onValueMouseOver={v => {
        if (willCancel) {
          clearTimeout(willCancel)
        }
        const value = buildValue(v)
        setAnchorPopover(value.position)
        setTransformHorizontal(value.transformHorizontal)
        setTransformVertical(value.transformVertical)
        setHovered(v)
        updateData(v)
      }}      
      onValueMouseOut={()=> {
        willCancel = setTimeout(()=>setHovered(null),100);
        updateData()
      }}
      style={{
        stroke: '#041e10',
        strokeOpacity: 1,
        strokeWidth: '1'
      }}   
      colorType="literal"
      data={data}
      height={280}
      width={280}>
      </Sunburst>          
      <Popover                   
        anchorReference="anchorPosition"  
        className="graph-popover"
        open={Boolean(hovered)}
        anchorPosition={anchorPopover}
        
        transformOrigin={{
          vertical: transformVertical,
          horizontal: transformHorizontal,
        }}
        onClose={()=>setHovered(null)}
        disableRestoreFocus
      >
        {hovered &&
        <>
          <div className="graph-popover-title">
          {t(`footprint:${hovered?.title}`)}
          </div>
          <div className="graph-popover-text">
          {`${hovered?.value.toFixed(2)} ${t('footprint:tonsCO2')} (${safePercent(hovered?.value, calculation?.total)}%) `}
          </div>
        </>}
      </Popover>
      <div className="footprint-embed">
        {calculation?.sections?.map((section, index) =>
          <SectionSelection showIcon showTotal key={index} section={section} selected={false} onChange={() => {}} />
        )}
      </div>
      {personal !== undefined &&        
        <div className="footprint-personal-graph">          
          <span className="personal-label">{t('questions:Personal').toUpperCase()}:</span>&nbsp;
          <span className="personal"><Odometer value={personal} format="(,ddd).dd" /></span>&nbsp;                      
          {tooltips && tooltips['personal'] &&
          <Tooltip id="personal" title={tooltips['personal'].title} text={tooltips['personal'].text}/>
        }
        </div>          
      }          
    </>
  )    
  
}