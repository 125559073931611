import './LanguageSelector.css'
import { useState, useRef, useContext } from 'react';
import { Button, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from '@material-ui/core';
import { useObservableCallback, useObservableState } from "observable-hooks"
import LanguageIcon from '@material-ui/icons/Language';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import CalculatorContext, { UNITS } from '../context/CalculatorContext'; 

const LANGUAGES = [
  { code: "en", name: "English", unit: UNITS.STANDARD },
  { code: "es", name: "Español", unit: UNITS.METRIC },
  { code: "fr", name: "Français", unit: UNITS.METRIC },
  { code: "de", name: "Deutsch", unit: UNITS.METRIC },
  { code: "pt", name: "Português", unit: UNITS.METRIC },
  { code: "ja", name: "日本語", unit: UNITS.METRIC },
  { code: "ru", name: "Русский", unit: UNITS.METRIC },
  { code: "zh", name: "中文", unit: UNITS.METRIC },
  { code: "hi", name: "हिन्दी", unit: UNITS.METRIC },
  { code: "ar", name: "العربية", unit: UNITS.METRIC },
  { code: "fil", name: "Filipino", unit: UNITS.METRIC }
];

interface ILanguageSelectorProps{
  compute?: boolean
}

function LanguageSelector(props:ILanguageSelectorProps) {
  const { t } = useTranslation();
  const anchorEl = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const { setSelectedLanguage, updateMetric, calculatorParams, status$} = useContext(CalculatorContext);
  const status = useObservableState(status$)

  const handleClickIcon = () => {
    setOpen(true)
  }

  const handleClickLanguage = (language: string) => {
    setOpen(false);
    const selectedLanguage = LANGUAGES.find(lang => lang.code === language);
    if (selectedLanguage) {
      localStorage.setItem("i18nextLng", language);
      setSelectedLanguage(language);
      i18n.changeLanguage(language);
      // Change unit and reset to original value
      // this will dismiss "dirty" values
      if(props.compute && calculatorParams['units'] != selectedLanguage.unit){
        if (status && calculatorParams[status.question.slug]){
          const originalParam = {
            key: status.question.slug,
            value: {...calculatorParams[status.question.slug]}
          }
          updateMetric(selectedLanguage.unit, status.question.slug, originalParam);
        }else{
          updateMetric(selectedLanguage.unit);
        }
      }
    }
  };

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div></div>
    // <div className='languageSelector'>
    //   <Button ref={anchorEl} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickIcon} startIcon={<LanguageIcon />}>
    //     <span style={{ color: "#fff", fontSize: '14px', textTransform: 'none' }}>{t("navbar:changeLang")}</span> <ArrowDropDownIcon />
    //   </Button>
    //   <Popper open={open} anchorEl={anchorEl.current} role={undefined} transition disablePortal>
    //     {({ TransitionProps, placement }) => (
    //       <Grow
    //         {...TransitionProps}
    //         style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
    //       >
    //         <Paper>
    //           <ClickAwayListener onClickAway={handleClose}>
    //             <MenuList autoFocusItem={open} id="menu-list-grow">
    //               {LANGUAGES.map((language) =>
    //                 <MenuItem key={language.code} onClick={() => handleClickLanguage(language.code)}>{language.name}</MenuItem>
    //               )}
    //             </MenuList>
    //           </ClickAwayListener>
    //         </Paper>
    //       </Grow>
    //     )}
    //   </Popper>
    // </div>
  )
}

export default LanguageSelector;
