import './App.css';
import 'typeface-space-mono'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory
} from "react-router-dom";
import {
  createTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
import Home from './screens/Home'
import Signup from './screens/Signup';
import Login from './screens/Login';
import Navbar from './components/Navbar';
import Authorize from './screens/Authorize';
import AuthContext, { initialAuth } from './context/AuthContext';
import AuthorizeWrapper from './components/AuthorizeWrapper';
import UserContext, { initialUser } from './context/UserContext';
import { grey } from '@material-ui/core/colors';
import { CalculatorContextProvider } from './context/CalculatorContext';
import Question from './components/Question'
import Responses from './screens/Responses'
import Enroll from './screens/Enroll'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createBrowserHistory } from 'history';

const theme = createTheme({
  palette: {
    primary: {
      light: '#acffa6',
      main: '#78e176',
      dark: '#43ae48',
      contrastText: '#000000',
    },
    secondary: {
      light: '#d5dbe0',
      main: '#00151e',
      dark: '#000000',
      contrastText: '#ffffff',
    },
  },
});

if (process.env.REACT_APP_SENTRY) {
  Sentry.init({
    dsn: "https://e154d8ef3cbd45a7a7d9b7f1d788c712@o1082787.ingest.sentry.io/6091718",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

function FallbackComponent() {
  return <div>An error has occurred</div>;
}

const fallback = <FallbackComponent />;

function App() {

  return (
    <Sentry.ErrorBoundary fallback={fallback} showDialog>
      <ThemeProvider theme={theme}>
        <AuthContext.Provider value={initialAuth}>
          <UserContext.Provider value={initialUser}>
            <Router>
              <CalculatorContextProvider>
                <div>
                  <Switch>
                    <Route path="/login" exact>
                      <Login />
                    </Route>
                    <Route path="/signup" exact>
                      <Signup />
                    </Route>
                    <Route path="/authorize" exact>
                      <Authorize />
                    </Route>
                    <Route path="/enroll/:pod" exact>                      
                      <Enroll />                      
                    </Route>
                    <Route path="/" exact>
                      <AuthorizeWrapper>
                        <Navbar />
                        <Question />
                      </AuthorizeWrapper>
                    </Route>
                    <Route path="/calculator">
                      <AuthorizeWrapper>
                        <Route path="/calculator/question/:question">
                          <Navbar />
                          <Question />
                        </Route>
                        <Route path="/calculator/footprint">
                          <Navbar graph={true} />
                          <Responses />
                        </Route>
                      </AuthorizeWrapper>
                    </Route>
                  </Switch>
                </div>
              </CalculatorContextProvider>
            </Router>
          </UserContext.Provider>
        </AuthContext.Provider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
