import './Navbar.css'
import { MouseEventHandler, useCallback, useContext,useState, useRef, useMemo } from 'react'
import { useHistory } from 'react-router'
import { FormControl,Input, Button, Menu,MenuItem,Popper, Grow, Paper, ClickAwayListener, MenuList, Step, StepLabel, Divider, Select } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useObservableCallback, useObservableState, useSubscription } from 'observable-hooks'
import FlightIcon from '@material-ui/icons/Flight';
import PeopleAlt from '@material-ui/icons/PeopleAlt';
import HomeIcon from '@material-ui/icons/Home';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import UserContext from '../context/UserContext'
import AuthContext from '../context/AuthContext'
import FootprintScore from './FootprintScore';
import { Stepper } from '@material-ui/core';
import CalculatorContext from '../context/CalculatorContext';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import StepIcon from './StepIcon';
import FootprintGraph from './FootprintGraph';
import FootprintScoreGraph from './FootprintScoreGraph';
import { ISectionType } from '../constant/questions';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';

const MAP_ICONS:Record<ISectionType,JSX.Element> = {
  [ISectionType.HOUSEHOLD]: <PeopleAlt />,
  [ISectionType.HOME]: <HomeIcon />,
  [ISectionType.FOOD]: <FastfoodIcon />,
  [ISectionType.TRANSPORT]: <DirectionsCarIcon />,  
  [ISectionType.TRAVEL]: <FlightIcon />,
  [ISectionType.SHOPPING]: <ShoppingCartIcon />,  
}

function getSteps() {
  return ['HOUSEHOLD', 'Create an ad group', 'Create an ad'];
}

interface INavbarProps{
  graph?: boolean
}

function Navbar(props:INavbarProps) {
  const {t} = useTranslation();

  const history = useHistory()
  const { question } = useParams<{question:string}>();
  const calculatorContext = useContext(CalculatorContext)  
  const status = useObservableState(calculatorContext.status$)
  const calculation = useObservableState(calculatorContext.calculation$, calculatorContext.getPreviousCalculation())
  const anchorEl = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const [showGraph, setShowGraph] = useState(false);
  const userContext = useContext(UserContext)
  const authContext = useContext(AuthContext)  
  const user = useObservableState(userContext.user)  
  const handleClickProfile:MouseEventHandler<HTMLButtonElement> = (event) => {    
    setOpen(true)
  };
  const handleClose = ()=>{
    setOpen(false)
  }
  const handleLogout = useCallback(() => {
    setOpen(false)
    calculatorContext.onLogout()
    authContext.logout()
  }, [])
  const handleFootprint =()=>{
    history.push('/calculator/footprint')
  }
  const handleQuestions =()=>{
    history.push('/')
  }    
  useEffect(()=>{    
    calculatorContext.processLocation({question});    
  }, [calculatorContext, question])
  useEffect(()=>{    
    userContext.requestUser();     
  }, [])
  const handleLogoClick =()=>{
    history.push('/')
  }
  const isNavigationDisabled = (slug:string)=>{
    if (status?.question){
      return status.question.disableNavigation && (!calculatorContext.calculatorParams[status.question.slug] || !calculatorContext.calculatorParams[slug])
    }
    return false
  }

  const goTo = (slug:string)=>{
    if(isNavigationDisabled(slug)){
      return
    }
    history.push(`/calculator/question/${slug}`)
  }  
  useEffect(()=>{
    if(history.location.pathname == '/calculator/footprint'){
      return setShowGraph(true)
    }
    setShowGraph(false)
  },[history.location])  
  return (
    <>
    <nav className="nav_component">
      <header>
        <div className="separator"/>
        <div style={{flexDirection: 'row', display: 'flex'}}>
          <div className="nav_logo" onClick={handleLogoClick}>
            <img src="/assets/images/Logo.svg"/>
          </div>
          <LanguageSelector compute={true}/>
        </div>
        <div className="nav_account">
          <img className="nav_account_avatar" src={user?.avatar || '/assets/images/default-avatar.png'}></img>        
          <div className="nav_account_menu">
            <Button ref={anchorEl}  aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickProfile} endIcon={<MoreVertIcon/>}>
              <span className="name">{user?.first_name}</span>
            </Button>
            <Popper open={open} anchorEl={anchorEl.current} role={undefined} transition disablePortal>          
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open} id="menu-list-grow">
                      <MenuItem onClick={handleQuestions}>{t("navbar:calculate")}</MenuItem>
                      <MenuItem onClick={handleFootprint}>{t("navbar:results")}</MenuItem>
                      <MenuItem onClick={handleLogout}>{t("navbar:logout")}</MenuItem>
							        <Divider />
                      <div className="version">Version: {process.env.REACT_APP_VERSION}</div>
                    </MenuList>  
                  </ClickAwayListener>
                  </Paper>
              </Grow>
            )}
            </Popper>
          </div>
        </div>
      </header>  
      {!showGraph &&
      <div className="nav_footprint">          
           
          <FootprintScore score={calculation?.total || 0} personal={calculation?.totalPersonal || 0}/>                              
      </div>}
      {showGraph &&          
      <div className="nav_footprint-graph">                
        <div className="title">
          {t("navbar:title")}
        </div>                        
        
        <FootprintGraph />              
      </div>}
      <footer>
        {!props.graph && (          
          <Stepper activeStep={0}>
            {status?.sections.map((section, index) => {                        
              return (
                <Step onClick={()=>goTo(section.firstSlug)} key={section.key} active={section.active} completed={section.completed}>
                  
                    <StepLabel StepIconComponent={StepIcon} >{t(`footprint:${section.label}`)}</StepLabel>
                  
                </Step>
              );
            })}
          </Stepper>                    
        )}
      </footer>
    </nav>
    {!props.graph && (   
    <div className="navbar-select">
    <FormControl  variant="outlined" >        
        <Select                    
          className="navbar-select-menu"
          value={status?.sections.find((s)=> s.active)?.key || ""}
          onChange={(event)=>{ 
            const slug = status?.sections.find((s)=> s.key ==event.target.value)?.firstSlug
            if(slug){
              goTo(slug)
            }
          }}
        >
          {status?.sections.map((section, index) =>                        
            <MenuItem value={section.key}>
              <span className="navbar-select-option">
                {MAP_ICONS[section.key]} 
                <span className="navbar-select-option-text">
                 {section.key.toLocaleUpperCase()}
                </span>
              </span>
            </MenuItem>
          )}          
        </Select>
      </FormControl>
    </div>)}
    </>
  )
}
export default Navbar


