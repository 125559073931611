import { Backdrop, CircularProgress } from '@material-ui/core';
import { useObservableState, useSubscription } from 'observable-hooks';
import React, { createContext, useContext, useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BehaviorSubject, catchError, concatAll, elementAt, first, last, map, merge, Observable, of, ReplaySubject, retry, share, Subject, subscribeOn, switchMap, take, takeLast, withLatestFrom,combineLatest, debounceTime } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import CALCULATOR_QUESTIONS, { CONVERSIONS, ICONS, IQuestionType, MAP_LABELS, UNITS, MAP_SECTION_LABELS } from '../constant/questions'
import AuthContext from './AuthContext';
import { FindQuestionResponse, ICalculation, ICalculatorInternalContext, COMPLETE_QUESTION, FIRST_QUESTION, ICalculatorContext, IInfoResponse, ILocation, ILocationRawResponse, ILocationRequest, ILocationResponse, ImplementedQuestions, IQuestionURLParams, ISectionStatus, IStatusCalculator, ICalculationResponse, ICalculationSection, IDashboardRecord, ICalculationFootprint, ITooltip, ITooltipsResponse, IDefaultValues, IDefaultRequest, ICalculationResponseWithParams, ILocationsRawResponse, IConversion, IConversionMap, IQuestion, IDirtyParam } from './CalculatorContext.d';
import {SunburstPoint} from 'react-vis';
import * as Sentry from "@sentry/react";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';


const spanish_country_names: Record<string, string> = {
  "Spain": "España",
  "Mexico": "México",
  "Panama": "Panamá",
  "Dominican Republic": "República Dominicana",
  "Peru": "Perú",
  "Equatorial Guinea": "Guinea Ecuatorial"
};

const getNextQuestion = (foundIndex: number, foundSection: number): ImplementedQuestions => {
  const calculatorQuestions = CALCULATOR_QUESTIONS;
  if (calculatorQuestions[foundSection].questions.length == foundIndex + 1) {
    if (calculatorQuestions.length == foundSection + 1) {
      return COMPLETE_QUESTION;
    }
    return calculatorQuestions[foundSection + 1].questions[0];
  }
  return calculatorQuestions[foundSection].questions[foundIndex + 1];
};

const getNextSkipQuestion = (foundIndex: number, foundSection: number): ImplementedQuestions => {
  const calculatorQuestions = CALCULATOR_QUESTIONS;
  if (calculatorQuestions[foundSection].questions.length <= foundIndex + 2) {
    if (calculatorQuestions.length == foundSection + 1) {
      return COMPLETE_QUESTION;
    }
    return calculatorQuestions[foundSection + 1].questions[0];
  }
  return calculatorQuestions[foundSection].questions[foundIndex + 2];
};

const getPreviousQuesion = (foundIndex: number, foundSection: number): ImplementedQuestions => {
  const calculatorQuestions = CALCULATOR_QUESTIONS;
  if (-1 == foundIndex - 1) {
    if (-1 == foundSection - 1) {
      return FIRST_QUESTION;
    }
    return calculatorQuestions[foundSection - 1].questions[calculatorQuestions[foundSection - 1].questions.length - 1];
  }
  return calculatorQuestions[foundSection].questions[foundIndex - 1];
};


const getPreviousSkipQuesion = (foundIndex: number, foundSection: number): ImplementedQuestions => {
  const calculatorQuestions = CALCULATOR_QUESTIONS;
  if (-1 >= foundIndex - 2) {
    if (-1 == foundSection - 1) {
      return FIRST_QUESTION;
    }
    return calculatorQuestions[foundSection - 1].questions[calculatorQuestions[foundSection - 1].questions.length - 2];
  }
  return calculatorQuestions[foundSection].questions[foundIndex - 2];
};


const shouldSkip = (params:any, question:IQuestion) =>{
  if(question.skippableBy){    
    if(params[question.skippableBy] && question.skippableByOptions?.includes(params[question.skippableBy]['selected'])){
      return true      
    }    
  }
  return false
}
type StatusParams = [IQuestionURLParams, Record<string,any>]

const getStatus = ([request, params]: StatusParams, t: (key: string) => string): IStatusCalculator => {
  const calculatorQuestions = CALCULATOR_QUESTIONS;
  const { currentQuestion, foundIndex, foundSection, currentSections } = findQuestion(request);
  let nextQuestion = getNextQuestion(foundIndex, foundSection);
  let previousQuestion = getPreviousQuesion(foundIndex, foundSection);
  if (shouldSkip(params, nextQuestion)) {
    nextQuestion = getNextSkipQuestion(foundIndex, foundSection);
  }
  if (shouldSkip(params, previousQuestion)) {
    previousQuestion = getPreviousSkipQuesion(foundIndex, foundSection);
  }
  const lastQuestions = calculatorQuestions[calculatorQuestions.length - 1].questions;
  let skippableOptions = undefined;
  if (currentQuestion.type == IQuestionType.CHOICE && currentQuestion.canSkipNext) {
    const skippedQuestionFind = findQuestion({ question: currentQuestion.canSkipNext });
    skippableOptions = skippedQuestionFind.currentQuestion.skippableByOptions;
  }
  return {
    question: currentQuestion,
    sections: currentSections,
    next: nextQuestion.slug,
    skippableOptions,
    previous: previousQuestion.slug,
    last: lastQuestions[lastQuestions.length - 1].slug
  };
};

const findQuestion = (request: IQuestionURLParams): FindQuestionResponse => {
  const calculatorQuestions = CALCULATOR_QUESTIONS;
  let foundQuestion: (ImplementedQuestions | null) = null;
  let foundIndex = 0;
  let foundSection = 0;
  const currentSections: ISectionStatus[] = [];
  if (request.question === undefined) {
    request.question = foundQuestion || calculatorQuestions[0].questions[0].slug;
  }
  calculatorQuestions.forEach((section, sectionIndex) => {
    const newSection: ISectionStatus = {
      active: false,
      completed: false,
      key: section.key,
      label: MAP_SECTION_LABELS[section.key],
      firstSlug: section.questions[0].slug
    };
    if (foundQuestion == null) {
      section.questions.forEach((question, questionIndex) => {
        if (question.slug == request.question) {
          foundQuestion = question;
          foundIndex = questionIndex;
          foundSection = sectionIndex;
        }
      });
      if (foundQuestion != null) {
        newSection.active = true;
      } else {
        newSection.completed = true;
      }
    } else {
      newSection.completed = false;
    }
    currentSections.push(newSection);
  });
  let currentQuestion = foundQuestion || calculatorQuestions[0].questions[0];
  return {
    currentQuestion,
    foundIndex,
    foundSection,
    currentSections
  };
};

const getNextStatus = (request: IQuestionURLParams): string => {
  const { currentQuestion, foundIndex, foundSection } = findQuestion(request);
  let nextQuestion = getNextQuestion(foundIndex, foundSection);
  if (currentQuestion.type === IQuestionType.CHOICE && currentQuestion.canSkipNext) {
    try {
      const savedParams = localStorage.getItem("calculator:params");
      if (savedParams) {
        const params = JSON.parse(savedParams);
        if (shouldSkip(params, nextQuestion)) {
          const newQuestion = getNextSkipQuestion(foundIndex, foundSection);
          return newQuestion.slug;
        }
      }
    } catch (e) { }
  }
  return nextQuestion.slug;
};


const fetchInfo = (status:IStatusCalculator) => {
  const token = localStorage.getItem('jwtToken');
  const selectedLanguage = i18n.language;

  return ajax<IInfoResponse>({
    url: `${process.env.REACT_APP_API_BASE_URL}/content/info/${status.question.slug}?lang=${selectedLanguage}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },    
    body:{
      tooltips: status.question.tooltipKeys
    }   
  }).pipe(    
    map(response=> {    
      const statusWithInfo = status
      statusWithInfo.question.info = response.response.info.text
      statusWithInfo.question.question = response.response.info.title
      statusWithInfo.question.tooltips = response.response.tooltips
      return statusWithInfo
    }),
  )
}

const fetchLocation = (status:ILocationRequest) => {  
  return ajax<ILocationsRawResponse>({
    url:`${process.env.REACT_APP_API_LOCATION_URL}/location`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',      
    },
    body: {
      location: status.zipcode
    }
  })
}
const fetchTooltips = (keys: string[]) => {
  const token = localStorage.getItem('jwtToken');
  const selectedLanguage = i18n.language; // Get the current language from i18n
  Sentry.addBreadcrumb({
    category: "footprint",
    message: "Request tooltips",
    level: Sentry.Severity.Info,
  });
  return ajax<ITooltipsResponse>({
    url: `${process.env.REACT_APP_API_BASE_URL}/content/tooltips?lang=${selectedLanguage}`, // Include language in URL
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: {
      tooltips: keys
    }
  })
}
const fetchServerCalculation = () =>{
  const token = localStorage.getItem('jwtToken')  
  Sentry.addBreadcrumb({
    category: "footprint",
    message: "Request previous calculation",
    level: Sentry.Severity.Info,
  });
  return ajax<ICalculationResponseWithParams>({
    url:`${process.env.REACT_APP_API_BASE_URL}/footprint/last`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }    
  })
}
const publishCalculation = (response: ICalculationResponseWithParams, t: (key: string) => string) => {
  const calculation = getTotals({
    footprint: response.footprint,
    defaults: response.defaults,
    personal: response.personal
  }, t);  // Pass the translation function here
  calculation$.next(calculation);
  params$.next(response.params);
}

const fetchCalculation = (params:Record<string,any>) =>{
  const token = localStorage.getItem('jwtToken')
  Sentry.addBreadcrumb({
    category: "footprint",
    message: "Request calculation",
    level: Sentry.Severity.Info,
  });
  params$.next(params)
  return ajax<ICalculationResponse>({
    url:`${process.env.REACT_APP_API_BASE_URL}/footprint/compute`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: {params}
  })
}
const fetchDefaults = (params:IDefaultRequest)=>{
  const token = localStorage.getItem('jwtToken')
  return ajax<IDefaultValues>({
    url:`${process.env.REACT_APP_API_BASE_URL}/footprint/defaults`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: {params}
  })
}

const transformLocation = (rawResponse: ILocationsRawResponse): ILocationResponse => {
  const location: ILocationResponse = {
    locations: []
  };

  const selectedLanguage = i18n.language;
  rawResponse?.locations?.forEach((raw, index) => {
    if (selectedLanguage === "es" && spanish_country_names[raw.label]) {
      location.locations.push({ ...raw, label: spanish_country_names[raw.label] });
    } else {
      location.locations.push(raw);
    }
  });

  return location;
}

const generateRecord = (label:string, icon:ICONS, value:number, total?:number):IDashboardRecord=>{  
  return  { 
    label,
    icon,
    value,
    percent: total && total!==0? value/total*100: 0,
    relation: 0
  }
}

const GRAPH_COLORS:Record<ICONS, string> = {
  [ICONS.FOOD]: "#BBBE2C",
  [ICONS.GOODS]: "#91959B",
  [ICONS.HOME]: "#239B8A",
  [ICONS.SERVICES]: "#5C6068",
  [ICONS.TRANSPORT]: "#34638B"  
}

const getGraph = (table: IDashboardRecord[], total: number): SunburstPoint[] => {
  const mapLabels = MAP_LABELS; // Generate mapLabels with the translation function

  const graphSections: Record<string, number> = {};
  const graph = table.filter((element) => {
    return Boolean(element.value);
  }).map((element, index) => {
    const section = mapLabels[element.icon];
    graphSections[section] = (graphSections[section] || 0) + element.value;
    return {
      title: element.label,
      section: section,
      color: GRAPH_COLORS[element.icon],
      size: element.value,
      value: element.value,
    };
  });

  // Sort sections
  const graphSectionsSorted = Object.keys(graphSections).sort((b, a) => {
    return graphSections[b] - graphSections[a];
  });

  const graphSectionsSortedMap: Record<string, number> = {};
  graphSectionsSorted.forEach((e, index) => {
    graphSectionsSortedMap[e] = index;
  });

  // Sort elements by section^10 + value
  return graph.sort((a, b) => {
    return (Math.pow(graphSections[b.section] + 1, 10) + b.value) - (Math.pow(graphSections[a.section] + 1, 10) + a.value);
  });
};

const getTable = (footprint:ICalculationFootprint, total:number):IDashboardRecord[]=>{
  if(!footprint){
    return []
  }    
  const data:IDashboardRecord[]=[]
  data.push(generateRecord("Air_Travel", ICONS.TRANSPORT, footprint['transport']['airTravel'], total))
  data.push(generateRecord("Car_Fuel", ICONS.TRANSPORT, footprint['transport']['carFuel'], total))
  data.push(generateRecord("Electricity", ICONS.HOME, footprint['home']['electricity'], total))
  data.push(generateRecord("Natural_Gas", ICONS.HOME, footprint['home']['naturalGas'], total))  
  data.push(generateRecord("Other", ICONS.FOOD, footprint['food']['snacks'], total))
  data.push(generateRecord("Heating_Oil_Other_Fuels", ICONS.HOME, footprint['home']['heating'], total))
  data.push(generateRecord("Home_Construction", ICONS.HOME, footprint['home']['construction'], total))
  data.push(generateRecord("Car_Manufacturing", ICONS.TRANSPORT, footprint['transport']['carManufacturing'], total))
  data.push(generateRecord("Water", ICONS.HOME, footprint['home']['water'], total))
  
  data.push(generateRecord("Meat", ICONS.FOOD, footprint['food']['beef'] + 
                                               footprint['food']['lamb'] + 
                                               footprint['food']['pork'] + 
                                               footprint['food']['otherMeat']
                                               , total))
  data.push(generateRecord("Poultry", ICONS.FOOD, footprint['food']['poultry'], total))
  data.push(generateRecord("Eggs", ICONS.FOOD, footprint['food']['eggs'], total))
  data.push(generateRecord("Seafood", ICONS.FOOD, footprint['food']['seafood'], total))  
  data.push(generateRecord("Cereals", ICONS.FOOD, footprint['food']['grains'], total))
  data.push(generateRecord("Fruits_Vegetables", ICONS.FOOD, footprint['food']['fruitsVegetables'], total))
  data.push(generateRecord("Dairy", ICONS.FOOD, footprint['food']['dairy'], total))
  data.push(generateRecord("Public_Transit", ICONS.TRANSPORT, footprint['transport']['publicTransportation'], total))
  data.push(generateRecord("Goods", ICONS.GOODS, footprint['goods']['goods'], total))
  data.push(generateRecord("Services", ICONS.SERVICES, footprint['services']['services'], total))
  const maxPercent = data.reduce((previous, current)=>{    
    return Math.max(previous, current.percent)
  },0.0)  
  return data.map((element)=>({
    ...element,
    relation: element.percent / maxPercent * 100
  }))  
}

const getTotals = (calculation: ICalculationResponse, t: (key: string) => string): ICalculation => {
  const sections: ICalculationSection[] = [];
  let total = 0;
  Object.entries(calculation.footprint).forEach((section) => {
    const totalSection = Object.values(section[1]).reduce((previous, current) => {
      return previous + current;
    }, 0);
    sections.push({
      key: section[0],
      value: totalSection,
      percent: 0
    });
    total += totalSection;
  });
  let totalPersonal = 0;
  Object.entries(calculation.personal).forEach((section) => {
    const totalSection = Object.values(section[1]).reduce((previous, current) => {
      return previous + current;
    }, 0);
    totalPersonal += totalSection;
  });
  const table = getTable(calculation.footprint, total);
  const graph = getGraph(table, total);  // Pass the translation function here
  const sortSections: Record<string, number> = { "home": 0, "food": 1, "transport": 2, "goods": 3, "services": 4 };

  const result = {
    ...calculation,
    sections: sections.map((section) => (
      {
        ...section,
        percent: section.value / total * 100
      }
    )).sort((b, a) => sortSections[b.key] - sortSections[a.key]),
    total: Math.round(total * 10) / 10,
    totalPersonal: Math.round(totalPersonal * 10) / 10,
    table,
    graph
  };
  localStorage.setItem("calculator:result", JSON.stringify(result));
  return result;
}

const curriedPublishCalculation = (t: (key: string) => string) => (response: ICalculationResponseWithParams) => {
  const calculation = getTotals({
    footprint: response.footprint,
    defaults: response.defaults,
    personal: response.personal
  }, t);
  calculation$.next(calculation);
  params$.next(response.params);
}

const curriedGetTotals = (t: (key: string) => string) => (response: ICalculationResponse) => {
  return getTotals(response, t);
}

const locationPath$ = new Subject<IQuestionURLParams>()
const locationCalculator$ = new Subject<ILocationRequest>()
const calculation$ = new ReplaySubject<ICalculation|undefined>()
const requestTooltips$ = new Subject<string[]>()
const tooltips$ = new ReplaySubject<Record<string,ITooltip>>()
//const requestDefaults$ = new Subject<IDefaultValues>()
const requestCalculation$ = new Subject<Record<string,any>>()
const requestServerCalculation$ = new Subject<void>()
const params$ = new ReplaySubject<Record<string,any>>(1)
const selectedCategories$ = new Subject<Record<string,boolean>>()
//const defaults$ = new ReplaySubject<IDefaultValues>()
const initialCalculator:ICalculatorInternalContext={  
  status$: combineLatest([locationPath$, params$]).pipe(
    map((params) => getStatus(params, (key: string) => key)),
    switchMap(fetchInfo),
    retry(3),
    share(),
    catchError((error, caught) => caught),
  ),
  processLocation: ($events) =>{
    locationPath$.next($events)
  },
  location$: locationCalculator$.pipe(
    debounceTime(500),
    switchMap(fetchLocation),    
    map((r)=>transformLocation(r.response)),
    share(),
    catchError( (error,caught) => caught),    
  ),
  getLocation: ($events) =>{
    locationCalculator$.next($events)
  },
  getCalculation: ($params) => {
    const token = localStorage.getItem('jwtToken')
    if(token){
      requestCalculation$.next($params)
    }
  },
  params$:params$,
  calculation$: calculation$,
  tooltips$: tooltips$,
  getTooltips: (keys)=>{
    const token = localStorage.getItem('jwtToken')
    if(keys && token){ 
      requestTooltips$.next(keys)
    }
  },
  getPreviousCalculation:()=>{    
    const result = localStorage.getItem("calculator:result")
    if(result){
      return JSON.parse(result)    
    }    
  },
  loadCalculation:()=>{
    const token = localStorage.getItem('jwtToken')
    if(token){
      requestServerCalculation$.next()
    }else{
      params$.next({})
    }
  },
  
  selectedCategories$:selectedCategories$,
  onSelectCategories:(selected:Record<string, boolean>)=>{
    selectedCategories$.next(selected)
  },
  selectedLanguage$: new BehaviorSubject<string>(i18n.language),
  selectedLanguage: i18n.language,
  setSelectedLanguage: () => {},
}
requestServerCalculation$.pipe(
  switchMap(fetchServerCalculation),
  map((r) => r.response),
  map(curriedPublishCalculation((key: string) => key)),  // Use the curried function
  catchError((error, caught) => {
    console.error(error);
    Sentry.captureException(error);
    params$.next({});
    return caught;
  }),
).subscribe();

requestTooltips$.pipe(
  switchMap(fetchTooltips),
  retry(0),
  map((r)=> r.response.tooltips),  
  share(),
  catchError( (error,caught) => {
    Sentry.captureException(error);
    return caught
  }),  
).subscribe(tooltips$)

requestCalculation$.pipe(
  switchMap(fetchCalculation),
  map((r) => r.response),
  map(curriedGetTotals((key: string) => key)),  // Use the curried function
  share(),
  catchError((error, caught) => {
    Sentry.captureException(error);
    return caught;
  }),
).subscribe(calculation$);

const transformText = (calculatorParams:Record<string,any>, key: string, conversion:IConversion, units:UNITS) =>{
  let shouldConvert = true
  if(conversion.ifHelpers.length>0 && calculatorParams[key]){    
    shouldConvert = false
    const helpers= calculatorParams[key].helpers || {}
    conversion.ifHelpers.forEach((ifHelper)=>{
      if(helpers[ifHelper.key] && helpers[ifHelper.key].value == ifHelper.option){
        shouldConvert = true;
      }
    })
  }
  if(shouldConvert && calculatorParams[key]){    
    calculatorParams[key].value = (calculatorParams[key].value * conversion.conversion[units]).toFixed(2)
  }
}
const transformVehicles = (calculatorParams:Record<string,any>, key: string, conversion:IConversion, units:UNITS) =>{  
  if(calculatorParams[key]){    
    calculatorParams[key].vehicles.forEach( (vehicle:any) =>{
      vehicle.driven = (vehicle.driven * conversion.conversion[units]).toFixed(2)      
      vehicle.perGallon = (235.215 / vehicle.perGallon ).toFixed(2)      
    }) 
  }
}
const transformMultiple = (calculatorParams:Record<string,any>, key: string, conversion:IConversion, units:UNITS) =>{  
  if(conversion.ifHelpers.length>0 && calculatorParams[key]){        
    const fields = Object.keys(calculatorParams[key])
    fields.forEach((field)=>{
      const shouldConvert = conversion.ifHelpers.some((ifHelper)=>{        
        return ifHelper.key== field
      })      
      if(shouldConvert){
        calculatorParams[key][field] = (calculatorParams[key][field] * conversion.conversion[units]).toFixed(2)
      }
    })        
  }
}

const transformParams = (calculatorParams:Record<string,any>, unit:UNITS)=>{
  Object.entries(calculatorParams).forEach((entry)=>{
    const conversion = CONVERSIONS[entry[0]]
    if(conversion){
      switch(conversion.type){
        case IQuestionType.NUMBER:
          transformText(calculatorParams, entry[0], conversion, unit)
          break
        case IQuestionType.VEHICLES:
          transformVehicles(calculatorParams, entry[0], conversion, unit)
          break
        case IQuestionType.MULTIPLE:
          transformMultiple(calculatorParams, entry[0], conversion, unit)
          break
      }
    }
  })
}


const getFoodValue=(food:any)=>{
  if(!food){
    return null
  }
  switch(food['selected']){
    case 'NEVER':
        return 0.0
    case 'ONCE':
        return 7
    case 'COUPLE':
        return 2.0
    case'MANY':
        return 21
    case 'OTHER':      
      return Number(food['value']) 
    default:
      return 0
  }
}

const getHouseholdFactor=(household:number)=>{
  if(household>2){
    return (household - 2) * 0.75 +2
  }
  return household
}

const FOOD_HOUSEHOLD = ['eat-beef', 'eat-lamb', 'eat-pork','eat-poultry', 'eat-eggs', 'eat-seafood', 'eat-dairy', 'eat-fruits-vegetables', 'eat-grains-baked-goods', 'eat-meat-alternatives', 'eat-snacks-drinks']
const transformHousehold = (calculatorParams:Record<string,any>, previousHousehold:number, newHousehold:number) =>{
  FOOD_HOUSEHOLD.forEach((food)=>{
    const currentValue = getFoodValue(calculatorParams[food])
    if(currentValue != null){
      const foodValue = currentValue / previousHousehold      
      calculatorParams[food] = {
        "selected": 'OTHER',
        "value": (foodValue * newHousehold).toFixed(2)
      } 
    }
  })  
}

const CalculatorContext = React.createContext<ICalculatorContext>({...initialCalculator,calculatorParams:{}, updateParam:()=>{},updateParams:()=>{},onLogout:()=>{}, updateMetric:()=>{}});

type Props = {
  children: React.ReactNode,
};

const ROUTE_TOOLTIPS:Record<string,string[]> = {
  
}

const MAIN_TOOLTIPS=['tons','household','personal','dont_know_defaults']
const CalculatorContextProvider = ({ children }: Props) => {
  const history = useHistory();
  const savedParams = localStorage.getItem("calculator:params");
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const { t } = useTranslation();

  useSubscription<string>(initialCalculator.selectedLanguage$, (language: string) => {
    setSelectedLanguage(language);
    initialCalculator.getTooltips([...MAIN_TOOLTIPS]); // Fetch tooltips when language changes
  });

  const changeLanguage = (language: string) => {
    setSelectedLanguage(language);
    initialCalculator.selectedLanguage$.next(language);
    i18n.changeLanguage(language);
    initialCalculator.getTooltips([...MAIN_TOOLTIPS]); // Fetch tooltips when language changes
  };

  useSubscription(initialCalculator.params$, params => {
    setCalculatorParams(params);
    setLoading(false);
  });

  let currentParams = {
    "units": UNITS.STANDARD
  };
  if (savedParams) {
    currentParams = JSON.parse(savedParams);
  }
  const [calculatorParams, setCalculatorParams] = useState<Record<string, any>>(currentParams);

  const updateMetric = useCallback((units: UNITS, key?: string, value?: any) => {
    const newCalculatorParams = { ...calculatorParams };
    newCalculatorParams['units'] = units;
    if (key !== undefined && value !== undefined && value.value !== undefined) {
      newCalculatorParams[key] = value.value;
    }
    localStorage.setItem("calculator:params", JSON.stringify(newCalculatorParams));
    transformParams(newCalculatorParams, units);
    setCalculatorParams(newCalculatorParams);
  }, [calculatorParams, setCalculatorParams]);

  const updateParams = useCallback((params: IDirtyParam[], cb: (nextSlug: string) => void) => {
    const newCalculatorParams = { ...calculatorParams };
    params.forEach(param => {
      newCalculatorParams[param.key] = param.value;
    });

    localStorage.setItem("calculator:params", JSON.stringify(newCalculatorParams));
    setCalculatorParams(newCalculatorParams);
    initialCalculator.getCalculation(newCalculatorParams);

    let statusQuestion = getNextStatus({
      question: params[0].key
    });

    cb(statusQuestion);
  }, [calculatorParams, setCalculatorParams]);

  const updateParam = (key: string, value: any, cb: (nextSlug: string) => void) => {
    updateParams([{ key, value }], cb);
  };

  const onLogout = () => {
    localStorage.removeItem("calculator:result");
    calculation$.next(undefined);
    setCalculatorParams({});
  };

  useSubscription(authContext.authenticate, ({ authenticated }) => {
    if (authenticated) {
      initialCalculator.loadCalculation();
    }
  });

  useEffect(() => {
    initialCalculator.loadCalculation();
  }, []);

  useEffect(() => {
    const route_tooltips = ROUTE_TOOLTIPS[history.location.pathname] || [];
    initialCalculator.getTooltips([...MAIN_TOOLTIPS, ...route_tooltips]);
  }, [history.location.pathname]);

  useEffect(() => {
    const savedParams = localStorage.getItem("calculator:params");
    if (savedParams) {
      const params = JSON.parse(savedParams);
      transformParams(params, params.units);
      setCalculatorParams(params);
    } else {
      transformParams(calculatorParams, calculatorParams.units);
    }
  }, []);

  return (
    <CalculatorContext.Provider value={{ ...initialCalculator, calculatorParams, updateParam, updateParams, updateMetric, onLogout, setSelectedLanguage: changeLanguage, selectedLanguage }}>
      {loading &&
        <Backdrop className={""} open={true} >
          <CircularProgress color="inherit" />
        </Backdrop>
      }
      {!loading && children}
    </CalculatorContext.Provider>
  );
};

export default CalculatorContext;
export { initialCalculator, CalculatorContextProvider, UNITS };
