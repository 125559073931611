import { FormControl } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React from "react";
import { useTranslation } from "react-i18next";
import { IQuestionNumberAdvance } from "../context/CalculatorContext.d";
import QuestionMultiple from "./QuestionMultiple";
import QuestionText from "./QuestionText";
import './QuestionAdvance.css';
import HintDefault from "./HintDefault";

interface IQuestionAdvanceProps {
  question: IQuestionNumberAdvance;
  default?: any;
  value?: any;
  onChange: (value: any) => void;
}

export default function QuestionAdvance(props: IQuestionAdvanceProps) {
  const { t } = useTranslation();
  const CHOICES = ['Simple', 'Advanced'];
  const { question } = props;
  const mode = props.value?.mode || CHOICES[0];
  const simpleValue = props.value?.total || { value: "" };

  const handleMode = (ev: React.MouseEvent, newValue: any) => {
    if (newValue) {
      props.onChange({
        ...props.value,
        mode: newValue,
        total: simpleValue,
      });
    }
  };

  const handleSimpleValue = (value: any) => {
    props.onChange({
      ...props.value,
      mode,
      total: { ...value },
    });
  };

  const handleDefault = () => {
    if (props.default && props.default.value) {
      props.onChange({
        ...props.value,
        mode,
        total: { value: parseFloat(props.default.value).toFixed(2) }
      });
    }
  };

  return (
    <div className="question-advance">
      <FormControl className="engine_type">
        <ToggleButtonGroup
          className="question-helper"
          value={mode}
          color="primary"
          exclusive
          onChange={handleMode}
          aria-label="text alignment"
        >
          {CHOICES.map(choice => (
            <ToggleButton key={choice} value={choice} selected={choice === mode} aria-label={choice}>
              {t(`questions:${choice}`)}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </FormControl>
      {mode === 'Simple' && (
        <>
          <QuestionText value={simpleValue} onChange={handleSimpleValue} type="number" placeholder={t(`questions:${question.placeholder}`)} helperPlaceholder={question.helperPlaceholder} />
          <HintDefault onClick={handleDefault} />
        </>
      )}
      {mode === 'Advanced' && (
        <QuestionMultiple default={props.default} question={question} onChange={props.onChange} value={props.value} />
      )}
    </div>
  );
}
