import { Mms } from "@material-ui/icons"
import { IConversion, IConversionMap, IConvertionHelper, ISectionCalculator, ISimpleTranslation } from "../context/CalculatorContext.d"

export enum UNITS{
  METRIC='metric',
  STANDARD="standard"
}
export const UNITS_LABEL={
  [UNITS.METRIC]: 'MetricUnits',
  [UNITS.STANDARD]: 'StandardUnits',
}

export enum IQuestionType{
  TEXT,
  ZIPCODE,
  CHOICE,
  CHOICETEXT,
  NUMBER,
  SLIDER,
  COMPLETE,
  FIRST,
  VEHICLES,
  MULTIPLE,
  NUMBER_ADVANCE
}

export enum ISectionType{
  HOUSEHOLD="Household",
  HOME="Home",
  TRANSPORT="Transport",
  FOOD="Food",
  TRAVEL="Travel",
  SHOPPING="Shopping"
}
export const MAP_SECTION_LABELS: Record<ISectionType, string> ={
  [ISectionType.HOUSEHOLD]:"Household",
  [ISectionType.HOME]:"Home",
  [ISectionType.TRANSPORT]:"Transport",
  [ISectionType.FOOD]:"Food",
  [ISectionType.TRAVEL]:"Travel",
  [ISectionType.SHOPPING]:"Spending"
}
export enum ICONS  {
  TRANSPORT,
  HOME,
  FOOD,
  GOODS,
  SERVICES
}
export const MAP_LABELS = {
  [ICONS.TRANSPORT]: "transport",
  [ICONS.HOME]:  "home",
  [ICONS.FOOD]:  "food",
  [ICONS.GOODS]: "goods",
  [ICONS.SERVICES]: "services"
}

const DIET_CHOICES = [
  {
    label: "Never", value:"NEVER", hint: (t:ISimpleTranslation) => `0 ${t('questions:servings/week')}`
  },
  {
    label: "couple", value:"COUPLE", hint: (t:ISimpleTranslation) => `3 ${t('questions:servings/week')}`
  },
  {
    label: "once", value:"ONCE", hint: (t:ISimpleTranslation) => `7 ${t('questions:servings/week')}`
  },
  {
    label: "Other", value: "OTHER", hint: (t:ISimpleTranslation) => `${t('questions:servings/week')}`
  }
]
const DIET_CHOICES_WITH_MANY = [
  {
    label: "Never", value:"NEVER", hint: (t:ISimpleTranslation) => `0 ${t('questions:servings/week')}`
  },
  {
    label: "couple", value:"COUPLE", hint: (t:ISimpleTranslation) => `3 ${t('questions:servings/week')}`
  },
  {
    label: "once", value:"ONCE", hint: (t:ISimpleTranslation) => `7 ${t('questions:servings/week')}`
  },
  {
    label: "many", value:"MANY", hint: (t:ISimpleTranslation) => `21 ${t('questions:servings/week')}`
  },
  {
    label: "Other", value: "OTHER", hint: (t:ISimpleTranslation) => `${t('questions:servings/week')}`
  }
]

const YES_NO_CHOICE= [
  {
    label: "Yes",
    value: "YES"
  },
  {
    label: "No",
    value: "NO"
  }
]
const M2_TO_F2={
  [UNITS.STANDARD]: 10.7639,
  [UNITS.METRIC]: 1/10.7639, 
}
const M3_TO_F3 ={
  [UNITS.STANDARD]: 35.314,
  [UNITS.METRIC]: 1/35.314, 
}
const GAL_TO_LT  ={
  [UNITS.STANDARD]: 1/4.546,
  [UNITS.METRIC]: 4.546, 
}

export const MILES_TO_KM = {
  [UNITS.STANDARD]: 1/1.60934,
  [UNITS.METRIC]: 1.60934, 
}
const HELPER_MILES_TO_KM = {
  [UNITS.STANDARD]: "miles/year",
  [UNITS.METRIC]: "km/year"
}
const PLACEHOLDER_MILES_TO_KM = {
  [UNITS.STANDARD]: "Enter miles",
  [UNITS.METRIC]: "Enter kilometers"
}
const CALCULATOR_QUESTIONS:ISectionCalculator[] = [
  {
    key: ISectionType.HOUSEHOLD,
    questions: [
      {
        slug: "where-do-you-live",    
        type: IQuestionType.ZIPCODE,
        active: true,
        disableNavigation: true
      },      
      {
        slug:"do-you-have-all-access",   
        type: IQuestionType.CHOICE,
        choices: YES_NO_CHOICE,
        disableNavigation: true
      },
      {
        slug:"how-many-people-in-household",     
        type: IQuestionType.NUMBER,
        placeholder: "enterNumber",
        min: 1,
        useDropdown: true,
        max: 10,        
        disableNavigation: true,
        default: {value:1},
        hideDefaultHint: true
      },      
      {
        slug:"want-use-income",
        type: IQuestionType.CHOICE,
        kind:"large",
        canSkipNext: 'what-is-your-income',
        choices: [
          {
            label: "yesUseIncome",
            value: "YES"
          },
          {
            label: "noUseIncome",
            value: "NO",
            message: "noDisclaimer"
          }
        ],
        disableNavigation: true
      },
      {
        slug:"what-is-your-income",
        skippableBy: "want-use-income",
        skippableByOptions: ["NO"],
        type: IQuestionType.NUMBER,
        placeholder: "dollarPlaceholder",
        helperPlaceholder: "dollarYear",
        disableNavigation: true
      }
    ]
  },
  {
    key: ISectionType.HOME,
    questions: [
      {
        slug: "rent-own",
        type:IQuestionType.CHOICE,
        choices: [
          {
            label: "Rent",
            value: "RENT"
          },
          {
            label: "Own",
            value: 'OWN'
          },
        ],
        disableNavigation: true
      },
      {
        slug:"living-space-area",
        type: IQuestionType.NUMBER,
        placeholder: "enterNumber",
        conversion: M2_TO_F2,
        helperPlaceholderUnit: {
          [UNITS.STANDARD]: "squareFeet",
          [UNITS.METRIC]: "squareMeters"
        },
        disableNavigation: true
      },
      {
        slug: "electricity-provider",
        type: IQuestionType.TEXT,
        placeholder: "enterName",
        default:{
          value: ""
        },
        disableNavigation: true
      },
      {
        slug: "pay-bills",
        type: IQuestionType.CHOICE,
        choices: YES_NO_CHOICE,
        disableNavigation: true
      },
      {
        slug:"how-much-electricity",      
        type: IQuestionType.NUMBER,
        placeholder: "enterAmount",
        helperPlaceholder: "{unit}/{period}",
        helpers:[
          {
            key: "unit",
            options:[
              {label: '$', value: 'MONEY', placeholder:"$"},
              {label: 'kWh', value: 'ENERGY', placeholder:"kWh"},
            ]
          },
          {
            key: "period",
            options: 
            [
              {label: 'perYear', value: 'YEAR', placeholder:"year"},
              {label: 'perMonth', value: 'MONTH', placeholder:"month"},
            ]
          }
        ],
        hintDefault: true,
        disableNavigation: true   
      },
      {
        slug:"renewable-energy-source",
        type:IQuestionType.CHOICE,
        canSkipNext: "renewable-energy-source-percent",
        choices: [
          {
            label: "SOLAR_PANELS",
            value: "SOLAR_PANELS"
          },
          {
            label: "GREEN_ENERGY",
            value: "GREEN_ENERGY"
          },
          {
            label: "COMMUNITY_SOLAR",
            value: "COMMUNITY_SOLAR"
          },          
          {
            label: "No",
            value: "NO"
          },
          {
            label: "Unsure",
            value: "SKIP"
          }
        ],
        disableNavigation: true
      },
      {
        slug:"renewable-energy-source-percent",  
        type: IQuestionType.SLIDER,
        steps: 10,
        min:0,
        max:100,
        default: {value:0},
        format: (value)=> `${Math.round(value)}%`,
        hintDefault: false,
        formatLabel: (value,t)=> `${value}%`,
        //placeholder: "Enter a percentage",
        //helperPlaceholder: "%",
        skippableBy:"renewable-energy-source",
        skippableByOptions: ["SOLAR_PANELS","NO","SKIP"],
        hideDefaultHint: true,
        disableNavigation: true
      },
      {
        slug:"how-much-natural-gas",
        type: IQuestionType.NUMBER,
        conversion: M3_TO_F3,
        default: {value:0},
        helpers:[
          {
            key: "unit",
            options:[
              {label: '$', value: 'MONEY', placeholder:"$"},
              {label: 'therms', value: 'THERMS', placeholder:"therms"},
              {label: 'm³', value: 'M3', placeholder:"m3", 
                placeholderUnit: {
                  [UNITS.STANDARD]: "ft³",
                  [UNITS.METRIC]: "m³"
                },labelUnit: {
                  [UNITS.STANDARD]: "ft³",
                  [UNITS.METRIC]: "m³"
                }},              
            ]
          },
          {
            key: "period",
            options: 
            [
              {label: 'perYear', value: 'YEAR',placeholder:"year"},
              {label: 'perMonth', value: 'MONTH',placeholder:"month"},
            ]
          }
        ],
        helperPlaceholder: "{unit}/{period}",
        placeholder:"enterAmount",
        hintDefault: true,
        disableNavigation: true
      },
      {
        slug:"how-much-heating",      
        type: IQuestionType.NUMBER,
        conversion: GAL_TO_LT,      
        default: {value:0},  
        helpers:[
          {
            key: "unit",
            options:[
              {label: '$', value: 'MONEY',placeholder:"$"},
              {label: 'gal', value: 'LT',placeholder:"gal", 
                placeholderUnit: {
                  [UNITS.STANDARD]: "gal",
                  [UNITS.METRIC]: "lt"
                },labelUnit: {
                  [UNITS.STANDARD]: "gal",
                  [UNITS.METRIC]: "lt"
                }},               
            ]
          },
          {
            key: "period",
            options: 
            [
              {label: 'perYear', value: 'YEAR',placeholder:"year"},
              {label: 'perMonth', value: 'MONTH',placeholder:"month"},
            ]
          }
        ],
        helperPlaceholder: "{unit}/{period}",
        placeholder:"enterAmount",
        hintDefault: true,
        disableNavigation: true
      },{
        slug:"how-much-water",      
        type: IQuestionType.SLIDER,
        format: (value, t)=> `${Math.round(value*100)}% ${t('questions:similarHouseholds')}`,
        formatLabel: (value,t)=> value==0?"0":`${value}X`,
        min:0,
        max:3,
        steps:3,
        default: {value:0},
        hintDefault: false,
        hideDefaultHint: true,
        disableNavigation: true
      }
    ],    
  },{
    key: ISectionType.FOOD,
    questions:[
      {
        slug: 'what-is-your-diet',
        type: IQuestionType.CHOICETEXT,
        choices:[
          {
            label: "includeMeat", value:"MEAT_SEAFOOD"
          },
          {
            label: "Vegetarian", value:"VEGETARIAN"  
          },
          {
            label: "Vegan", value: "VEGAN"
          },{
            label: "Other", value: "OTHER"
          }
        ],
        hintDefault: false,
        disableNavigation: true
      },{
        slug: "eat-beef",
        type: IQuestionType.CHOICE,
        choices: DIET_CHOICES_WITH_MANY,
        hintDefault: true,
        default:{
          selected: "NEVER"
        },
        disableNavigation: true
      },{
        slug: "eat-lamb",
        type: IQuestionType.CHOICE,
        choices: DIET_CHOICES_WITH_MANY,
        hintDefault: true,
        default:{
          selected: "NEVER"
        },
        disableNavigation: true
      },{
        slug: "eat-pork",
        type: IQuestionType.CHOICE,
        choices: DIET_CHOICES_WITH_MANY,
        hintDefault: true,
        default:{
          selected: "NEVER"
        },
        disableNavigation: true
      },{
        slug: "eat-poultry",
        type: IQuestionType.CHOICE,
        choices: DIET_CHOICES_WITH_MANY,
        hintDefault: true,
        default:{
          selected: "NEVER"
        },
        disableNavigation: true
      },{
        slug: "eat-eggs",
        type: IQuestionType.CHOICE,
        choices: DIET_CHOICES_WITH_MANY,
        hintDefault: true,
        default:{
          selected: "NEVER"
        },
        disableNavigation: true
      },{
        slug: "eat-seafood",
        type: IQuestionType.CHOICE,
        choices: DIET_CHOICES_WITH_MANY,
        hintDefault: true,
        default:{
          selected: "NEVER"
        },
        disableNavigation: true
      },{
        slug: "eat-dairy",
        type: IQuestionType.CHOICE,
        choices: DIET_CHOICES_WITH_MANY,
        hintDefault: true,
        default:{
          selected: "NEVER"
        },
        disableNavigation: true
      },{
        slug: "eat-fruits-vegetables",
        type: IQuestionType.CHOICE,
        choices: DIET_CHOICES_WITH_MANY,
        hintDefault: true,
        default:{
          selected: "NEVER"
        },
        disableNavigation: true
      },{
        slug: "eat-grains-baked-goods",
        type: IQuestionType.CHOICE,
        choices: DIET_CHOICES_WITH_MANY,
        hintDefault: true,
        default:{
          selected: "NEVER"
        },
        disableNavigation: true
      },{
        slug: "eat-meat-alternatives",
        type: IQuestionType.CHOICE,
        choices: DIET_CHOICES_WITH_MANY,
        hintDefault: true,
        default:{
          selected: "NEVER"
        },
        disableNavigation: true
      },{
        slug: "eat-snacks-drinks",
        type: IQuestionType.CHOICE,
        choices: DIET_CHOICES_WITH_MANY,
        hintDefault: true,
        default:{
          selected: "NEVER"
        },
        disableNavigation: true
      },{
        slug: "compost-food-waste",
        type: IQuestionType.SLIDER,
        format: (value,t)=> `${Math.round(value)}%`,
        formatLabel: (value,t)=> `${value}%`,
        min:0,
        max:100,
        steps:10,
        default:{
          value:0
        },
        hintDefault: false,
        hideDefaultHint: true,
        disableNavigation: true
      },
    ]
  },{
    key: ISectionType.TRANSPORT,
    questions:[
      {
        slug:"how-many-vehicles",
        type: IQuestionType.VEHICLES,
        conversion: MILES_TO_KM,
        tooltipKeys: ["vehicle-driven", "vehicle-efficiency"],
        disableNavigation: true
      },
      /*{
        slug:"ride-public-transit",
        type: IQuestionType.CHOICE,
        choices: YES_NO_CHOICE
      },*/
      {
        slug:"public-transit",
        type: IQuestionType.MULTIPLE,       
        conversion: MILES_TO_KM, 
        fields: [
          {
            key:"BUS",
            label:"Bus",
            placeholder:'enterNumber',
            helperPlaceholderUnit: HELPER_MILES_TO_KM,
            helperPlaceholder: "miles/year"
          },
          {
            key:"TRANSIT",
            label:"TransitRail",
            placeholder:'enterNumber',            
            helperPlaceholder: "miles/year",
            helperPlaceholderUnit: HELPER_MILES_TO_KM,
          },
          {
            key:"COMMUTER",
            label:"CommuterRail",
            placeholder:'enterNumber',
            helperPlaceholder: "miles/year",
            helperPlaceholderUnit: HELPER_MILES_TO_KM,
          },
          {
            key:"RAIL",
            label:"InterCityRail",
            placeholder:'enterNumber',
            helperPlaceholder: "miles/year",
            helperPlaceholderUnit: HELPER_MILES_TO_KM,
          }
        ],
        default: {
          BUS: "0",
          COMMUTER: "0",
          RAIL: "0",
          TRANSIT: "0",
        },
        disableNavigation: true
      },{
        slug: "flights",
        type: IQuestionType.MULTIPLE,
        fields: [
          {
            key:"SHORT",
            label:"Short",
            placeholder:'oneWayFlights',
            helperPlaceholder: "flights/year"
          },
          {
            key:"MEDIUM",
            label:"Medium",
            placeholder:'oneWayFlights',
            helperPlaceholder: "flights/year"
          },
          {
            key:"LONG",
            label:"Long",
            placeholder:'oneWayFlights',
            helperPlaceholder: "flights/year"
          },
          {
            key:"EXTENDED",
            label:"Extended",
            placeholder:'oneWayFlights',
            helperPlaceholder: "flights/year"
          }
        ],
        default:{
          EXTENDED: "0",
          LONG: "0",
          MEDIUM: "0",
          SHORT: "0",
        },
        disableNavigation: true
      }
    ]
  },{
    key: ISectionType.SHOPPING,
    questions: [
      {
        slug:"spend-goods",
        placeholder:"enterAmount",
        helperPlaceholder: "$/month",
        type: IQuestionType.NUMBER_ADVANCE, 
        tooltipKeys:['spend-goods_FURNITURE', 
                     'spend-goods_CLOTHING', 
                     'spend-goods_ENTERTAINMENT', 
                     'spend-goods_PAPER', 
                     'spend-goods_PERSONAL',
                     'spend-goods_AUTO',
                     'spend-goods_MEDICAL'],       
        fields:[
          {
            key:"FURNITURE",
            label:"FurnitureAppliances",
            placeholder:'enterAmount',
            helperPlaceholder: "$/month"
          },
          {
            key:"CLOTHING",
            label:"Clothing",
            placeholder:'enterAmount',
            helperPlaceholder: "$/month"
          },
          {
            key:"ENTERTAINMENT",
            label:"Entertainment",
            placeholder:'enterAmount',
            helperPlaceholder: "$/month"
          },
          {
            key:"PAPER",
            label:"PaperOffice",
            placeholder:'enterAmount',
            helperPlaceholder: "$/month"
          },
          {
            key:"PERSONAL",
            label:"PersonalCareCleaning",
            placeholder:'enterAmount',
            helperPlaceholder: "$/month"
          },
          {
            key:"AUTO",
            label:"AutoParts",
            placeholder:'enterAmount',
            helperPlaceholder: "$/month"
          },
          {
            key:"MEDICAL",
            label:"MedicalSupplies",
            placeholder:'enterAmount',
            helperPlaceholder: "$/month"
          }
        ],
        disableNavigation: true
      },
      {
        slug:"spend-services",   
        placeholder:"enterAmount",
        helperPlaceholder: "$/month",
        type: IQuestionType.NUMBER_ADVANCE,
        tooltipKeys:['spend-services_HEALTH', 
                    'spend-services_INFORMATION', 
                    'spend-services_EDUCATION',
                    'spend-services_VEHICLE', 
                    'spend-services_PERSONAL',
                    'spend-services_HOUSEHOLD',
                    'spend-services_ORGANIZATIONS',
                    'spend-services_OTHER'],       
        fields:[
          {
            key:"HEALTH",
            label:"HealthCare",
            placeholder:'enterAmount',
            helperPlaceholder: "$/month"
          },
          {
            key:"INFORMATION",
            label:"InformationCommunication",
            placeholder:'enterAmount',
            helperPlaceholder: "$/month"
          },
          {
            key:"EDUCATION",
            label:"Education",
            placeholder:'enterAmount',
            helperPlaceholder: "$/month"
          },
          {
            key:"VEHICLE",
            label:"VehicleService",
            placeholder:'enterAmount',
            helperPlaceholder: "$/month"
          },
          {
            key:"PERSONAL",
            label:"PersonalBusinessFinance",
            placeholder:'enterAmount',
            helperPlaceholder: "$/month"
          },
          {
            key:"HOUSEHOLD",
            label:"HouseholdMaintenanceRepair",
            placeholder:'enterAmount',
            helperPlaceholder: "$/month"
          },
          {
            key:"ORGANIZATIONS",
            label:"OrganizationsCharity",
            placeholder:'enterAmount',
            helperPlaceholder: "$/month"
          },
          {
            key:"OTHER",
            label:"OtherServices",
            placeholder:'enterAmount',
            helperPlaceholder: "$/month"
          },
        ],
        disableNavigation: true
      }
    ]
  }
]
const CONVERSIONS:Record<string,IConversion> = {}
CALCULATOR_QUESTIONS.forEach((entry)=>{
  entry.questions.forEach((question)=>{
    if(question.conversion){
      let ifHelpers:IConvertionHelper[]= [];
      if(question.type == IQuestionType.NUMBER){        
        question.helpers?.forEach(helper => {
          helper.options.forEach(option=>{
            if(option.labelUnit){
              ifHelpers.push({
                key: helper.key,
                option: option.value
              })
            }
          })
        });
      }
      if(question.type == IQuestionType.MULTIPLE){        
        question.fields.forEach((field)=>{
          if(field.helperPlaceholderUnit){
            ifHelpers.push({
              key: field.key,
              option: ''
            })
          }
        })
      }
      CONVERSIONS[question.slug] = {
        type: question.type,
        conversion:question.conversion,  
        ifHelpers      
      }
    }
  })
})

export default CALCULATOR_QUESTIONS
export {CONVERSIONS}
