import { Button, FormControl, InputAdornment, OutlinedInput, TextField } from "@material-ui/core";
import { useMemo, useState } from "react";
import NumberFormat from "react-number-format";
import { IQuestionType } from "../constant/questions";
import { IQuestionSelect } from "../context/CalculatorContext.d";
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import './QuestionChoice.css'
import { useTranslation } from 'react-i18next';

interface IQuestionChoiceProps{
  question: IQuestionSelect
  value?: any
  onChange: (value:any)=>void
  type: IQuestionType.CHOICE | IQuestionType.CHOICETEXT
}

export default function QuestionChoice(props:IQuestionChoiceProps){
  const {t} = useTranslation();
  const selected = props.value?.selected || null
  const inputValue = props.value?.value || ""
  const { question} = props
  const onChange = (value:any)=>{    
    props.onChange({selected: value})
  }
  const onChangeText = (value:string)=> {    
    props.onChange({
      selected: "OTHER",
      value
    })
  }
  const onFocus = (value:any)=>{
    props.onChange({
      selected: value,
      value: inputValue
    })
  }
  const alertMessage = useMemo(()=>{
    const selectedQuestion = question.choices.find((choice)=>choice.value == selected)
    if(selectedQuestion && selectedQuestion.message){
      return t(`questions:${selectedQuestion.message}`) 
    }
    return null
    //return selectedQuestion? t(`questions:${selectedQuestion.message}`) : null
  },[selected])  
  return (
    <div className={`question-choice${question.kind =='large'?" large":""}`} >
      {question.choices.map((choice, index)=>{
        if(choice.value != 'OTHER')
          return (
            <Button className={(selected == choice.value?'selected ':' ') + (choice.hint? 'with-hint ':'')} 
                    key={index} onClick={()=> onChange(choice.value)} variant="contained" >
              {t(`questions:${choice.label}`)}
              {choice.hint && (<span className="hint">{choice.hint(t)}</span>)}
            </Button>
          )      
        if(choice.value == 'OTHER')
          return (
            <FormControl key={index} className={(selected == choice.value?'selected ':' ') + (choice.hint? 'with-hint ':'')} variant="outlined">
              {props.type != IQuestionType.CHOICE ?
              <OutlinedInput 
                value={inputValue} 
                onFocus={()=> onFocus(choice.value)} 
                onChange={(ev)=> onChangeText(ev.target.value)} 
                type="text"                 
                placeholder={t("questions:Other")}
                endAdornment={choice.hint && <InputAdornment position="end">{t(`questions:${choice.hint}`)}</InputAdornment>}
              />
              :
              <NumberFormat 
                thousandSeparator={true}
                value={inputValue} 
                customInput={OutlinedInput}
                onFocus={()=> onFocus(choice.value)} 
                onValueChange={(ev)=> {if (inputValue !== ev.value) {onChangeText(ev.value)}}}                 
                inputProps={
                  {min:0}
                }
                placeholder={t("questions:Other")}
                endAdornment={choice.hint && <InputAdornment position="end">{choice.hint(t)}</InputAdornment>}
              />  
              }
            </FormControl>
          )
      })}      
      { alertMessage &&
        <div className="choice-alert">
          <ReportProblemOutlinedIcon/> {alertMessage}
        </div>
      }
    </div>
  )
}