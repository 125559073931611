import './Login.css'
import { FormControl,Input, Button, Paper, TextField, Snackbar, IconButton, SnackbarCloseReason } from '@material-ui/core';
import React, { useCallback, useContext, useState } from 'react';
import {useHistory} from 'react-router-dom'
import AuthContext from '../context/AuthContext';
import { useObservableCallback, useSubscription } from 'observable-hooks';
import { IAuthLoginResponse, IAuthRequest, IForgotRequest } from '../context/AuthContext.d';
import CloseIcon from '@material-ui/icons/Close';

import { useTranslation } from 'react-i18next';
import LanguageSelector from '../components/LanguageSelector';


function Login() {
  const {t} = useTranslation();

  const [open, setOpen] = useState(false);

  const authContext = useContext(AuthContext)  
  const history = useHistory();
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [userError, setUserError] = useState<string|null>(null)
  const [passwordError, setPassswordError] = useState<string|null>(null)
  const [loading, setLoading] = useState(false)
  const [onLogin, loginChange$] = useObservableCallback<IAuthLoginResponse, IAuthRequest>(authContext.login)
  const [onForgot, forgotChange$] = useObservableCallback<boolean, IForgotRequest>(authContext.forgotPassword)
  useSubscription(loginChange$,({error})=>{
    setLoading(false)
    if(error){
      setUserError(error)
    }
  })
  useSubscription(forgotChange$, (result)=>{
    setLoading(false)
    if(!result){
      setUserError( t("login:errorRequestingPassword"))
    }else{
      setOpen(true)
    }
  })
  const handleSignup = useCallback(()=>{
    setUserError(null)
    setLoading(false)
    history.push('/signup')
  },[setUserError])
  const handleLogin = useCallback(()=>{
    if(!username){      
      setUserError(t("login:errorEmail") )
      return
    }
    if(!password){
      setPassswordError(t("login:errorPassword") )
      return
    }
    setUserError(null)
    setPassswordError(null)
    setLoading(true)
    onLogin({username,password})
  },[username,password])
  const handleForgot = ()=>{
    if(!username){
      setUserError(t("login:errorEmail"))
      return
    }
    setLoading(true)
    setUserError(null)
    onForgot({
      username
    })
  }
  const handleClose = (event:React.SyntheticEvent<any>, reason:SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const checkEnterAndLogin = (ev:React.KeyboardEvent<HTMLInputElement>) =>{
    if(ev.key === 'Enter'){
      handleLogin()
    }
  }

  return (
	<>
		<nav className="nav_component">
		  <header>
		    <div className="nav_logo">
		      <img src="/assets/images/Logo.svg" />
          <LanguageSelector/>
		    </div>
			</header>
		</nav>
		<div className="screen_login">
      <div className="wrapper">
        <h1>{t("login:title")}</h1>
        <form>
          <FormControl margin="normal">
            <TextField error={!!userError} helperText={userError} value={username} onChange={(ev)=>setUsername(ev.target.value)} label={t("login:email")} variant="outlined" required onKeyPress={checkEnterAndLogin}/>
          </FormControl>          
          <FormControl margin="normal">
            <TextField error={!!passwordError} helperText={passwordError} type="password" value={password} onChange={(ev)=>setPassword(ev.target.value)} label={t("login:password")} variant="outlined" required onKeyPress={checkEnterAndLogin}/>
          </FormControl>          
        </form>
        <Button disabled={loading} variant="contained" color="primary" onClick={handleLogin} className="primary">{t("login:login")}</Button>
        <footer>
        {t("login:needSignup")}  <a onClick={handleSignup}>{t("login:createAccount")}</a>
        </footer>
        <footer>
          <a onClick={handleForgot}>{t("login:forgot")}</a>
        </footer>        
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={t("login:haveAccount")}
        action={
          <>            
            <IconButton size="small" aria-label="close" color="inherit" onClick={()=>setOpen(false)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        />
    </div>
  </>
	);
}

export default Login;