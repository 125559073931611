import './Enroll.css'
import { FormControl,Input, Button, Paper, TextField, Snackbar, IconButton, SnackbarCloseReason } from '@material-ui/core';
import React, { useCallback, useContext, useState,useEffect, useMemo } from 'react';
import {useHistory} from 'react-router-dom'
import AuthContext from '../context/AuthContext';
import { useObservableCallback, useSubscription, useObservableEagerState } from 'observable-hooks';
import { IAuthLoginResponse, IAuthRequest, IAuthResponse, IForgotRequest } from '../context/AuthContext.d';
import CloseIcon from '@material-ui/icons/Close';
import {CircularProgress} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { ReplaySubject } from 'rxjs';

const silentAuth$ = new ReplaySubject<IAuthResponse>();

function Enroll() {

  const { pod } = useParams<{pod:string}>();

  const [open, setOpen] = useState(false);
  const [authorized, setAuthorized] = useState(false)
  const [authError, setAuthError] = useState(false)
  const [enrolled, setEnrolled] = useState(false)
  const [enrolledError, setEnrolledError] = useState(false)

  const authContext = useContext(AuthContext)      


  const handleClose = (event:React.SyntheticEvent<any>, reason:SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useSubscription(authContext.enroll, (responseEnroll)=>{
    if(!responseEnroll.error){
      setEnrolled(true)
      setTimeout(()=>{
        window.location.href = responseEnroll.url
      },2000)
    }else{
      console.log(responseEnroll.message)
      setEnrolledError(true)
    }
  })

  useEffect(()=>{
    authContext.silentAuthenticate(silentAuth$)
  },[])

  // Get authentication result 
  useSubscription(silentAuth$, (result)=>{
    if(!result.authenticated){
      setAuthError(true);
    }else{
      setAuthorized(true)
      authContext.requestEnroll({pod})
    }
  });


  const renderedMessage = useMemo(()=>{
    if (authError) {
      return (
     <>
          <h1>There was an error checking your user</h1>          
          <p>
            Please log in into your account and try this link again.
          </p>              
        </>
      )
    }
    if(enrolledError) {
      return (
      <>
          <h1>There was an error enrolling you into the course</h1>          
          <p>
            Please contact support.
          </p>
        </>
      )
    }
    if(!authorized && !authError) {
      return (
      <>
           <h1>Checking your credentials</h1>          
           <p>
             Please wait
           </p>
           <CircularProgress color="inherit" />    
         </>)
     }
    if(authorized && !enrolled){
      return (<>
        <h1>Enrolling into course...</h1>
        <p>
          Please wait
        </p>
        <CircularProgress color="inherit" />
      </>)
    }
    if(authorized && enrolled) {
      return (<>
        <h1>Enroll complete!</h1>
        <p>
          Redirecting to your courses...
        </p>
      </>)
    }
  },[authorized, authError, enrolled, enrolledError])

  return (
	<>
		<nav className="nav_component">
		  <header>
		    <div className="nav_logo">
		      <img src="/assets/images/Logo.svg" />
		    </div>
			</header>
		</nav>
		<div className="enroll_login">
      <div className="wrapper">
       {renderedMessage}
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="There was an issue enrolling you into the course, please log in and try again."
        action={
          <>            
            <IconButton size="small" aria-label="close" color="inherit" onClick={()=>setOpen(false)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        />
    </div>
  </>
	);
}

export default Enroll;