import { Autocomplete } from "@material-ui/lab";
import React, { useCallback, useContext, useState } from "react";
import { ILocation, IQuestionZipcode } from "../context/CalculatorContext.d";
import CalculatorContext from "../context/CalculatorContext";
import QuestionText from "./QuestionText";
import { useObservableState, useSubscription } from "observable-hooks";
import { OutlinedInput, TextField } from "@material-ui/core";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import './QuestionZipcode.css'


const spanish_country_names: Record<string, string> = {
  "Spain": "España",
  "Mexico": "México",
  "Panama": "Panamá",
  "Dominican Republic": "República Dominicana",
  "Peru": "Perú",
  "Equatorial Guinea": "Guinea Ecuatorial"
};

const inverse_spanish_country_names: Record<string, string> = Object.fromEntries(
  Object.entries(spanish_country_names).map(([key, value]) => [value, key])
);

interface IQuestionZipcodeProps{
  value?: ILocation
  onChange: (value:any)=>void
  question: IQuestionZipcode
}

export default function QuestionZipcode(props:IQuestionZipcodeProps){
  const { t } = useTranslation();
  const [value, setValue] = useState<ILocation|null>(props.value || null)
  const [inputValue, setInputValue] = useState("")
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<ILocation[]>([]);

  const calculatorContext = useContext(CalculatorContext)    
  const loading = open && options.length === 0;
  useSubscription(calculatorContext.location$,(response)=>{
    setOptions(response.locations)
  })
  const onChange = (ev: React.ChangeEvent<{}>, value: ILocation|null)=>{
    if(value){      
      setValue(value)
      props.onChange(value)
    }
  }
  const handleChangeZipcode =(ev:React.ChangeEvent<HTMLInputElement>)=>{
    setOptions([])
    let zipcodeValue = ev.target.value;
    const selectedLanguage = i18n.language;
    if (selectedLanguage === "es") {
      const translatedKey = inverse_spanish_country_names[zipcodeValue];
      if (translatedKey) {
        zipcodeValue = translatedKey;
      }
    }
    if(zipcodeValue.length >3){
      calculatorContext.getLocation({
        zipcode: zipcodeValue
      })
    }
    setInputValue(ev.target.value)
  }
  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      value={value}
      className="question-zipcode"
      loading={loading}
      options={options}      
      onChange={onChange}
      filterOptions={(options)=>options}
      getOptionLabel={(location) => location.label || ""}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}      
      renderInput={(params) => (        
        <TextField {...params} value={inputValue} placeholder={t("questions:EnterYourZipcode")} onChange={handleChangeZipcode} variant="outlined" />
      )}
    />
  )
}