import { IconButton, Popover, Typography } from "@material-ui/core";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { useState } from "react";
import './Tooltip.css'
interface ITooltip{
  id: string
  title: string
  text: string
}

export default function Tooltip(props:ITooltip){
  const [anchorEl, setAnchorEl] = useState<HTMLElement|null>(null);  
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlePopoverOpen = (event:React.MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };  
  return(
    <>
      <Typography  aria-owns={open ? 'mouse-over-popover' : undefined}  
                  className="tooltip-button" 
                  aria-haspopup="true"
                  aria-describedby={props.id}  
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}>
        <HelpOutlineIcon />
      </Typography>
      <Popover        
        className="tooltip-footprint"
        id={props.id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableRestoreFocus
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <div>
          {props.text}
        </div>
      </Popover>
    </>
  )
}