import { Observable } from "rxjs"
import { ICONS, IQuestionType,ISectionType, UNITS } from "../constant/questions"
import {SunburstPoint} from 'react-vis';


export interface IQuestionSelectChoice{
  label: string
  value: any
  hint?: ISimpleTranslation
  placeholder?: string
  placeholderUnit?: IConversionStringMap
  labelUnit?: IConversionStringMap
  message?: string
}
export interface IQuestionHelper{
  key: string
  options: IQuestionSelectChoice[]
}
export interface IQuestionField{
  key: string
  label: string
  placeholder: string  
  helperPlaceholder: string
  helperPlaceholderUnit?: IConversionStringMap
}
export interface ITooltip{
  title: string
  text: string
}
export interface ITooltipsResponse{
  tooltips: Record<string, ITooltip>
}
export type IConversionMap =Record<UNITS, number>
export type IConversionStringMap =Record<UNITS, string>
export interface IConvertionHelper{
  key: string
  option: string
}

export interface IConversion{
  type: IQuestionType,
  conversion: IConversionMap
  ifHelpers: IConvertionHelper[]
}

export interface IQuestion{
  slug: string  
  question?: string
  placeholder?: string
  helperPlaceholderUnit?: IConversionStringMap
  conversion?: IConversionMap
  helperPlaceholder?:string  
  type: IQuestionType
  info?: string
  active?: boolean  
  hintDefault?: boolean
  helpers?: IQuestionHelper[]
  tooltipKeys?: string[]
  tooltips?: Record<string, ITooltip>
  skippableBy?: string
  skippableByOptions?: string[]
  default?: any
  disableNavigation?: boolean
  hideDefaultHint?: boolean
}
export interface IDirtyParam { 
  key: string
  value: any
}
export interface IQuestionSelect extends IQuestion{  
  type: IQuestionType.CHOICE | IQuestionType.CHOICETEXT
  choices: IQuestionSelectChoice[]
  kind?: string
  canSkipNext?: string  
}
export interface IQuestionText extends IQuestion{  
  type: IQuestionType.TEXT
}
export interface IQuestionZipcode extends IQuestion{  
  type: IQuestionType.ZIPCODE
}
export interface IQuestionNumber extends IQuestion{ 
  type: IQuestionType.NUMBER
  min?: number
  max?: number
  useDropdown?: boolean  
}
export type ISimpleTranslation = (string) => string;

export interface IQuestionSlider extends IQuestion{ 
  type: IQuestionType.SLIDER
  min?: number
  max?: number
  steps?: number
  format: (value:number, t:ISimpleTranslation)=>string 
  formatLabel: (value:number, t:ISimpleTranslation)=>string 
}
export interface IQuestionVehicles extends IQuestion{ 
  type: IQuestionType.VEHICLES
}
export interface IQuestionComplete extends IQuestion{ 
  type: IQuestionType.COMPLETE
}
export interface IQuestionFirst extends IQuestion{ 
  type: IQuestionType.FIRST
}
export interface IQuestionFields extends IQuestion{
  fields: IQuestionField[]
}
export interface IQuestionMultiple extends IQuestionFields{ 
  type: IQuestionType.MULTIPLE,  
}
export interface IQuestionNumberAdvance extends IQuestionFields{ 
  type: IQuestionType.NUMBER_ADVANCE  
}

export interface IVehicleStatus{
  index: number
  engineType: string
  perGallon: number | null
  driven: number | null
}

export type ImplementedQuestions = (
  IQuestionText | 
  IQuestionNumber | 
  IQuestionSelect |
  IQuestionComplete|
  IQuestionFirst|
  IQuestionVehicles| 
  IQuestionZipcode|
  IQuestionMultiple|
  IQuestionNumberAdvance|
  IQuestionSlider)

export interface ISectionCalculator{
  key: ISectionType  
  questions: Array<ImplementedQuestions>
}

export interface ISectionStatus{
  key: ISectionType
  label: string
  active: boolean,
  completed: boolean
  firstSlug: string
}
export interface IQuestionURLParams{
  question: string
}
export interface IStatusCalculator { 
  question: ImplementedQuestions
  sections: ISectionStatus[]
  next: string
  previous: string
  last: string
  skippableOptions?: string[]
}
export interface ILocation {
  value: string
  label: string  
  country: string
  type: string
}
export interface ILocationResponse{
  locations: ILocation[]
}
export interface ILocationRequest{
  zipcode: string
}
export interface ICalculationFootprint extends Record<string, Record<string,number>>{
}
export interface ICalculationResponse {
  footprint: ICalculationFootprint
  personal: ICalculationFootprint
  defaults: IDefaultValues
}
export interface ICalculationSection {
  key: string
  value: number
  percent: number
}

export interface IDashboardRecord {
  label:string
  icon: ICONS
  value: number
  percent: number
  relation: number
}

export interface ICalculation extends ICalculationResponse{
  total: number
  totalPersonal: number
  sections: ICalculationSection[]
  table: IDashboardRecord[]
  graph: SunburstPoint[]
}
export interface ICalculationResponseWithParams extends ICalculationResponse {
  params: Record<string,any>
}

export type IDefaultValues = Record<string, any>
export type IDefaultRequest = Record<string, any>  
export interface ICalculatorInternalContext{
  tooltips$: Observable<Record<string,ITooltip>>
  getTooltips: (keys:string[])=>void
  status$: Observable<IStatusCalculator>
  processLocation: ($events:IQuestionURLParams)=>void
  location$: Observable<ILocationResponse>
  getLocation: ($events:ILocationRequest)=>void  
  calculation$: Observable<ICalculation|undefined>
  params$: Observable<Record<string,any>>
  getCalculation: ($events:Record<string,any>)=>void
  getPreviousCalculation: ()=>ICalculation|undefined  
  loadCalculation: ()=>void
  selectedCategories$: Observable<Record<string, boolean>>
  onSelectCategories: (selected:Record<string, boolean>)=>void
  selectedLanguage$: BehaviorSubject<string>
  selectedLanguage: string
  setSelectedLanguage: (language: string) => void
  //defaults$: Observable<IDefaultValues>
  //getDefault: ($events:IDefaultRequest)=>void
}
export interface ICalculatorContext extends ICalculatorInternalContext{
  calculatorParams: Record<string,any>
  updateParam: (key:string, value:any, cb:(nextSlug:string)=>void) => void
  updateParams: (params:IDirtyParam[], cb:(nextSlug:string)=>void) => void
  updateMetric: (units:UNITS, key?:string, value?:any) => void
  onLogout: () => void
  setSelectedLanguage: (language: string) => void
  selectedLanguage: string
}
export interface ILocationRawSelectedLocation{
  zipcode: string
  city: string
  state: string
  country: string
}
export interface ILocationRawResponse{
  label: string
  type: string
  value: string
  country: string
}
export interface ILocationsRawResponse{
  locations: ILocationRawResponse[]  
}
export interface IInfoRequest{
  key: string
}
export interface IInfoResponse{
  info:{
    text: string
    title: string
  }
  tooltips: Record<string,ITooltip>
}
export interface IParam{
  key: string
  value: any
}

export const COMPLETE_QUESTION:IQuestionComplete = {
  slug: 'COMPLETE',
  question: 'COMPLETE',
  type: IQuestionType.COMPLETE
}
export const FIRST_QUESTION:IQuestionComplete = {
  slug: 'FIRST',
  question: 'FIRST',
  type: IQuestionType.FIRST
}

export interface FindQuestionResponse{
  currentQuestion: ImplementedQuestions
  foundIndex: number
  foundSection: number
  currentSections: ISectionStatus[]
}