import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Popover, TextField } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useCallback, useState } from "react";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { UNITS } from "../constant/questions";
import { ITooltip, IVehicleStatus } from "../context/CalculatorContext.d";
import Tooltip from "./Tooltip";
import { useTranslation } from 'react-i18next';

import './Vehicle.css'
interface IVehicleProps{
  vehicle: IVehicleStatus
  onChange: (vehicle:IVehicleStatus)=>void
  tooltipEfficiency?: ITooltip    
  tooltipDriven?: ITooltip    
  units: UNITS
}
export const CHOICES= [
  "Gasoline",
  "Diesel",
  "Hybrid",
  "Electric"
]
export const MPG_UNITS = {
  [UNITS.METRIC]: "L/100km",
  [UNITS.STANDARD]: "mpg",
}
export const MPG_UNITS_LABEL = {
  [UNITS.METRIC]: "LitresPer100km",
  [UNITS.STANDARD]: "MilesPerGallon",
}
export const DRIVEN_UNITS = {
  [UNITS.METRIC]: "km/year",
  [UNITS.STANDARD]: "miles/year",
}
export const DRIVEN_UNITS_LABEL = {
  [UNITS.METRIC]: "KilometersDrivenPerYear",
  [UNITS.STANDARD]: "MilesDrivenPerYear",
}
export default function Vehicle(props:IVehicleProps){
  const {t} = useTranslation();

  const {vehicle} = props;  
  const handleEngine = (ev:React.MouseEvent, newValue:any)=>{
    if(newValue){
      if(newValue == CHOICES[3]){
        props.onChange({
          ...vehicle,
          engineType: newValue,
          perGallon: 0
        })
      }else{
        props.onChange({
          ...vehicle,
          engineType: newValue
        })
      }
    }
  }
  const handlePerGallon = (value:NumberFormatValues)=>{    
    //console.log(vehicle)
    props.onChange({
      ...vehicle,
      perGallon: value.floatValue == undefined ?  null : value.floatValue
    })    
  }
  const handleDriven = (value:NumberFormatValues)=>{    
    props.onChange({
      ...vehicle,
      driven: value.floatValue == undefined ?  null : value.floatValue
    })
  }

  return (
    <section className="vehicle">
      <h1>
        {t("questions:Vehicle")} {vehicle.index} 
      </h1>
    
      <FormControl>
        <label>
          {t("questions:EngineType")}
        </label>
        <ToggleButtonGroup
        className="question-helper"
        value={vehicle.engineType || CHOICES[0]}
        color="primary"
        exclusive
        onChange={handleEngine}
        aria-label="text alignment"
      >
        {CHOICES.map(choice=>
          <ToggleButton value={choice} aria-label={choice}>
            {t(`questions:${choice}`)}
          </ToggleButton>
        )}                  
      </ToggleButtonGroup>
    </FormControl>
    { vehicle.engineType != CHOICES[3] &&
    <FormControl className="driven">
      <label>
        {t(`questions:${MPG_UNITS_LABEL[props.units]}`)}
      </label>
      <div>
        <NumberFormat 
          customInput={OutlinedInput}
          value={vehicle.perGallon}  
          placeholder={t("enterNumber")}
          onValueChange={handlePerGallon}
          inputProps={{min:0}}
          thousandSeparator={true}
          endAdornment={<InputAdornment position="end">{t(`questions:${MPG_UNITS[props.units]}`)}</InputAdornment>}
        />
        {props.tooltipEfficiency &&
          <Tooltip id="miles" title={props.tooltipEfficiency.title} text={props.tooltipEfficiency.text}/>
        }
      </div>
    </FormControl>
    }
    <FormControl className="driven">
      <label>
      {t(`questions:${DRIVEN_UNITS_LABEL[props.units]}`)}
      </label>
      <div>
        <NumberFormat 
          thousandSeparator={true}
          customInput={OutlinedInput}
          value={vehicle.driven} 
          placeholder={t("enterNumber")}
          onValueChange={handleDriven}
          inputProps={{min:0}}
          endAdornment={<InputAdornment position="end">{t(`questions:${DRIVEN_UNITS[props.units]}`)}</InputAdornment>}
        />
        {props.tooltipDriven &&
          <Tooltip id="miles" title={props.tooltipDriven.title} text={props.tooltipDriven.text}/>
        }
      </div>
    </FormControl>
    </section>
  )
}