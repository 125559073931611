import { useCallback, useMemo, useState } from "react";
import { ICalculationFootprint, IDashboardRecord } from "../context/CalculatorContext.d";
import FlightIcon from '@material-ui/icons/Flight';
import HomeIcon from '@material-ui/icons/Home';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingsIcon from '@material-ui/icons/Settings';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import './DashboardTable.css'
import { ICONS, MAP_LABELS } from "../constant/questions";
import { useTranslation } from 'react-i18next';

type IFilterFunc = (a: IDashboardRecord, b: IDashboardRecord) => number;
interface IFilters extends Record<FILTER, IFilterFunc> { }

enum FILTER {
  TONS_UP,
  TONS_DOWN,
  NAME_UP,
  NAME_DOWN
}

const MAP_FILTERS: IFilters = {
  [FILTER.TONS_UP]: (a, b) => {
    return a.value - b.value;
  },
  [FILTER.TONS_DOWN]: (a, b) => {
    return b.value - a.value;
  },
  [FILTER.NAME_UP]: (a, b) => {
    return a.label.toLowerCase() < b.label.toLowerCase() ? 1 : -1;
  },
  [FILTER.NAME_DOWN]: (a, b) => {
    return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
  },
};

export interface IDashboardTableProps {
  table?: IDashboardRecord[];
  total: number;
  selected: Record<string, boolean>;
}

export default function DashboardTable(props: IDashboardTableProps) {
  const { t } = useTranslation();

  // Generate mapLabels with translation function
  const mapLabels = useMemo(() => MAP_LABELS, []);

  const MAP_ICONS = {
    [ICONS.TRANSPORT]: <FlightIcon className={mapLabels[ICONS.TRANSPORT]} />,
    [ICONS.HOME]: <HomeIcon className={mapLabels[ICONS.HOME]} />,
    [ICONS.FOOD]: <FastfoodIcon className={mapLabels[ICONS.FOOD]} />,
    [ICONS.GOODS]: <ShoppingCartIcon className={mapLabels[ICONS.GOODS]} />,
    [ICONS.SERVICES]: <SettingsIcon className={mapLabels[ICONS.SERVICES]} />,
  };

  const [filter, setFilter] = useState<FILTER>(FILTER.TONS_DOWN);
  const data = props.table || [];

  const changeFilterTons = useCallback(() => {
    setFilter(filter === FILTER.TONS_DOWN ? FILTER.TONS_UP : FILTER.TONS_DOWN);
  }, [filter]);

  const changeFilterItem = useCallback(() => {
    setFilter(filter === FILTER.NAME_UP ? FILTER.NAME_DOWN : FILTER.NAME_UP);
  }, [filter]);

  const filterSelections = (value: IDashboardRecord) => {
    const key = mapLabels[value.icon];
    return props.selected[key] === undefined ? true : props.selected[key];
  };

  return (
    <div className="dashboard_table">
      <header>
        <div className="header-item" onClick={changeFilterItem}>
          <span>{t('footprint:emissionsContributor')}</span>
          {filter === FILTER.NAME_DOWN && <ArrowDownwardIcon />}
          {filter === FILTER.NAME_UP && <ArrowUpwardIcon />}
        </div>
        <div className="header-tons" onClick={changeFilterTons}>
          <span>
            {t('footprint:tons')}<br />
            <span className="total">(% {t('footprint:ofTotal')})</span>
          </span>
          {filter === FILTER.TONS_DOWN && <ArrowDownwardIcon />}
          {filter === FILTER.TONS_UP && <ArrowUpwardIcon />}
        </div>
      </header>
      {data.sort(MAP_FILTERS[filter]).filter(filterSelections).map((row, index) => (
        <div key={index} className="row">
          <div className="item">{t(`footprint:${row.label}`)}</div>
          <div className="icon">{MAP_ICONS[row.icon]}</div>
          <div className={`graph ${mapLabels[row.icon]}`}>
            <div className="line" style={{ width: `${row.relation}%` }}></div>
          </div>
          <div className="value">
            {row.value.toFixed(2)}&nbsp;<span>({row.percent.toFixed(1)}%)</span>
          </div>
        </div>
      ))}
      <footer>
        <div className="footer-label">
          {t('footprint:totalTable')}:
        </div>
        <div className="footer-value">
          {data.reduce((previous, current) => previous + current.value, 0.0).toFixed(2)}
        </div>
      </footer>
    </div>
  );
}
