import './Signup.css'
import { FormControl,Input, Button, Paper, TextField, Icon } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import React, { useCallback,useContext, useRef, useState } from 'react';
import {useHistory} from 'react-router-dom'
import { useObservableCallback, useSubscription } from 'observable-hooks';
import AuthContext from '../context/AuthContext';
import {IAuthContext, IAuthLoginResponse, IAuthRequest} from '../context/AuthContext.d'
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import LanguageSelector from '../components/LanguageSelector';

interface IPasswordErrorsProps{
  errors: Array<string>
}

function PasswordErrors ({errors}:IPasswordErrorsProps){
  return (<>
    {errors.map((error)=>(
      <div>
        {error}
      </div>
    ))}
    </>)
}

function checkText(text:string,t: TFunction<"translation", undefined>){
  const findNumbers = /\d/;
  if(findNumbers.test(text)){
    return t("signup:notNumbers")
  }
  return null
}

function Signup() {
  const {t} = useTranslation();

  const fileInput = useRef<HTMLInputElement>(null)
  const authContext = useContext(AuthContext)
  const history = useHistory();
  const [onSignup, signupChange$] = useObservableCallback(authContext.signup)
  const [onLogin, loginChange$] = useObservableCallback<IAuthLoginResponse, IAuthRequest>(authContext.login)  
  const [email, setEmail] = useState('')
  const [firstNameError, setFirstNameError] = useState<string|null>(null)
  const [lastNameError, setLasttNameError] = useState<string|null>(null)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [confirm, setConfirm] = useState('')

  const [passwordError, setPasswordError] = useState<string|null>(null)  

  const [pod, setPod] = useState('')
  const [photo, setPhoto] = useState<FileList | null>(null)
  const [loading, setLoading] = useState(false)
  const [userError, setUserError] = useState<string|null>(null)
  const defaultEmailMessage =  t("signup:useLetters") 
  const defaultPassword = t("signup:mixLetters") 
  const handleSignup = useCallback(()=>{    
    setFirstNameError(null)
    setLasttNameError(null)
    setUserError(null)
    setPasswordError(null)
    
    const validateFirstName = checkText(firstName,t);
    if(validateFirstName){
      setFirstNameError(validateFirstName)
      return
    }

    const validateLastName = checkText(lastName,t);
    if(validateLastName){
      setLasttNameError(validateLastName)
      return
    }

    if(!firstName){
      setFirstNameError( t("signup:enterFirstName")  )
      return
    }
    if(!lastName){
      setLasttNameError( t("signup:enterLastName")  )
      return
    }
    if(!email){
      setUserError(t("signup:enterEmail") )
      return
    }
    if(!password){
      setPasswordError(t("signup:enterPassword") )
      return
    }
    if(password != confirm){
      setPasswordError(t("signup:wrongPassword") )
      return
    }
  
    onSignup({
      email,
      firstName,
      lastName,
      password,
      pod,
      photo: photo && photo.length > 0 ? photo[0] : undefined
    })
  },[email, firstName, lastName, password, pod, photo, confirm])
  const handlePhotoSelect = useCallback(()=>{
    fileInput.current?.click()    
  },[fileInput])
  useSubscription(signupChange$,({error, messages,errorPassword})=>{    
    if(error){
      setLoading(false)      
      if(messages && messages['email']){
        setUserError(messages['email'].join(''))
        return
      }
      setUserError(error)
    }else if(errorPassword){
      setPasswordError(errorPassword)
    }else{
      onLogin({username:email, password})
    }
    
  },()=>{
    setLoading(false)
    setUserError(t("signup:errorSignup"))
  })
  useSubscription(loginChange$,({error})=>{
    setLoading(false)
    if(error){
      setUserError(error)
    }
  })
  useEffect(()=>{
    if(!password || !confirm){
      setPasswordError(null)
      return
    }else if(password != confirm){
      setPasswordError(t("signup:wrongPassword"))      
    }else{
      setPasswordError(null)
    }
  },[confirm,password])
  const handleLogin = useCallback(()=>{
    history.push('/login')
  },[])

  const passwordErrorElement = passwordError? <PasswordErrors errors={passwordError.split('\n')}/> : null  
  return (
	<>
		<nav className="nav_component">
		  <header>
		    <div className="nav_logo">
		      <img src="/assets/images/Logo.svg" />
          <LanguageSelector/>
		    </div>
			</header>
		</nav>
		<div className="screen_signup">
      <div className="wrapper">
        <h1>{t("signup:title")} </h1>
        <form>
          <div className="double-field">
            <FormControl margin="normal">
              <TextField error={Boolean(firstNameError)} helperText={firstNameError} label={t("signup:firstName")}  value={firstName} onChange={(ev)=>setFirstName(ev.target.value)} variant="outlined" required/>
            </FormControl>
            <FormControl margin="normal">
              <TextField error={Boolean(lastNameError)} helperText={lastNameError} label={t("signup:lastName")} value={lastName} onChange={(ev)=>setLastName(ev.target.value)} variant="outlined" required/>
            </FormControl>          
          </div>
          <FormControl margin="normal">
            <TextField error={Boolean(userError)} helperText={userError || defaultEmailMessage} label={t("signup:email")} value={email} onChange={(ev)=>setEmail(ev.target.value)} variant="outlined" required/>
          </FormControl>          
            {/*<FormControl margin="normal">
              <TextField label='Pod' value={pod} onChange={(ev)=>setPod(ev.target.value)} variant="outlined" required/>
            </FormControl>*/}
          {/*
            <Button variant="outlined" className="profile-button"  onClick={handlePhotoSelect} endIcon={<CloudUploadIcon/>}>Upload a profile photo</Button>                    
            <input ref={fileInput} type="file" className="hide" onChange={ev => setPhoto(ev.target.files)}/>
          */}
          <div className="double-field">
            <FormControl margin="normal">
              <TextField error={Boolean(passwordError)} helperText={passwordErrorElement} type="password" label={t("signup:password")} value={password} onChange={(ev)=>setPassword(ev.target.value)} variant="outlined" required/>
            </FormControl>
            <FormControl margin="normal">
              <TextField type="password" label={t("signup:confirmPassword")} value={confirm} onChange={(ev)=>setConfirm(ev.target.value)} variant="outlined" required/>
            </FormControl>
          </div>
          {!Boolean(passwordError) &&
            <p className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-filled Mui-required">{defaultPassword}</p>          
          }
        </form>
        <Button disabled={loading} variant="contained"  color="primary" onClick={handleSignup} className="primary">{t("signup:signup")}</Button>
        <footer>
        {t("signup:alreadyAccount")}  <a onClick={handleLogin}>{t("signup:login")}</a>
        </footer>
      </div>
    </div>
	</>
  );
}

export default Signup;