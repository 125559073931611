import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import en from "./languages/en.json";
import es from "./languages/es.json";
import de from "./languages/de.json";
import fr from "./languages/fr.json";
import pt from "./languages/pt.json";
import ja from "./languages/ja.json";
import ru from "./languages/ru.json";
import zh from "./languages/zh.json";
import hi from "./languages/hi.json";
import ar from "./languages/ar.json";
import fil from "./languages/fil.json";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en, // English
  es, // Spanish
  de, // German
  fr, // French
  pt, // Portuguese
  ja, // Japanese
  ru, // Russian
  zh, // Chinese
  hi, // Hindi
  ar, // Arabic
  fil // Filipino
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)

  .init({
    order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    supportedLngs: ['en', 'es', 'de', 'fr', 'pt', 'ja', 'ru', 'zh', 'hi', 'ar', 'fil'],
    resources,
    fallbackLng: "en", // PS - changing to "en" for a more widely accepted default fallback language
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;