import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { IQuestionSelect, IQuestionVehicles, ITooltip, IVehicleStatus } from "../context/CalculatorContext.d";
import Vehicle, {CHOICES} from './Vehicle'
import './QuestionVehicles.css'
import { MILES_TO_KM, UNITS } from "../constant/questions";
import { useTranslation } from 'react-i18next';

import HintDefault from "./HintDefault";

interface IQuestionVehiclesProps{  
  value: {
    selected: number
    vehicles: IVehicleStatus[]
  }
  tooltipEfficiency?: ITooltip
  tooltipDriven?: ITooltip
  onChange: (value:any)=>void
  units: UNITS,
  default?: any
}
const CARS_OPTIONS = [0,1,2,3,4,5]

export default function QuestionVehicles(props:IQuestionVehiclesProps){
  const { t } = useTranslation();

  const selected = props.value?.selected || ""
  const vehicles = props.value?.vehicles || []
  const handleChange = (event:React.ChangeEvent<{name?: string| undefined,value: unknown }>)=>{
    const nSelect = event.target.value    
    if(nSelect === undefined || typeof nSelect !== 'number') {
      return
    }
    const newVehicles:IVehicleStatus[] = []    
    for(let i=0;i<nSelect;i++){
      if(vehicles[i]){
        newVehicles[i] = vehicles[i]
      }else{
        newVehicles[i] = {
          index:i+1,
          engineType: CHOICES[0],
          perGallon: 0,
          driven: 0
        }
      }
    }
    props.onChange({
      selected: nSelect,
      vehicles: newVehicles
    })
  }
  
  const handleChangeVehicle = (newVehicle:IVehicleStatus)=>{
    const newVehicles =[...vehicles]
    newVehicles[newVehicle.index-1] = newVehicle
    props.onChange({
      selected,
      vehicles: newVehicles
    })
  }

  

  const handleDefault= (index:number)=>{
    if(props.default){
      const newVehicles =[...vehicles]
      newVehicles[index] = {
        index:index+1,
        engineType: CHOICES[0],
        perGallon: props.units == UNITS.STANDARD ? props.default.mpg: (235.215 / Number(props.default.mpg)).toFixed(2) ,
        driven: props.units == UNITS.STANDARD ? props.default.driven: (MILES_TO_KM[UNITS.METRIC] *  Number(props.default.driven)).toFixed(2)
      }
      props.onChange({
        selected,
        vehicles: newVehicles
      })
    }
  }
  return (
    <div className="question-vehicles">
        <FormControl className="number-vehicles" variant="outlined">
          <Select value={selected} displayEmpty onChange={handleChange}>
            <MenuItem value={""} disabled>
              {t("questions:selectNumber")}
            </MenuItem>
            {CARS_OPTIONS.map((index)=>
              <MenuItem key={index} value={index}>{index}</MenuItem>
            )}            
          </Select>
        </FormControl>
        {vehicles.map((vehicle,index)=>
          <>
            <Vehicle units={props.units} key={index} vehicle={vehicle} onChange={handleChangeVehicle} tooltipEfficiency={props.tooltipEfficiency} tooltipDriven={props.tooltipDriven}/>
            <HintDefault onClick={()=>handleDefault(index)}/>            
          </>
        )}
    </div>
  )
}