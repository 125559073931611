import { useEffect, useContext, useState } from "react";
import {Backdrop, CircularProgress} from '@material-ui/core';
import { useObservable, useObservableCallback, useSubscription } from 'observable-hooks';
import AuthContext from '../context/AuthContext';
import { useHistory } from "react-router-dom";

export default function Authorize() {  
  const history = useHistory()
  const authContext = useContext(AuthContext)
  useSubscription(authContext.authenticate, ({ authenticated }) => {
    if (authenticated) {
      history.push('/')

    } else {
      history.push('/login')
    }
  })
  useEffect(()=>{
    authContext.requestAuthenticate()
  },[])
  return (  
    <Backdrop className={""} open={true} >
        <CircularProgress color="inherit" />
    </Backdrop>
  );
}