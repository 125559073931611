import { useCallback, useContext } from "react"
import './HintDefault.css'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import Tooltip from "./Tooltip";
import { useObservableState } from "observable-hooks";
import CalculatorContext from "../context/CalculatorContext";
import { useTranslation } from 'react-i18next';

interface IHintDefault{
  onClick?: ()=>void
}
export default function HintDefault(props:IHintDefault){
  const {t} = useTranslation();

  const calculatorContext = useContext(CalculatorContext)  
  const tooltips = useObservableState(calculatorContext.tooltips$) 
  const addDefault = useCallback(()=>{
    if(props.onClick){
      props.onClick()
    }
  },[props])
  return(
    <section className="question-hint">
      <span>
      {t("questions:dontKnow")}&nbsp;<a onClick={addDefault}>{t("questions:useAverage")}</a> {t("questions:forYourArea")}.
      </span>
      {tooltips &&  <Tooltip id="household" title={tooltips['dont_know_defaults'].title} text={tooltips['dont_know_defaults'].text}/>}
    </section>
  )
}