import { useObservableState } from 'observable-hooks'
import { useHistory } from 'react-router'
import { useCallback, useContext, useEffect, useState, useRef } from 'react'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DashboardTable from '../components/DashboardTable'
import SectionSelection from '../components/SectionSelection'
import CalculatorContext from '../context/CalculatorContext'
import './Responses.css'
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import EditIcon from '@material-ui/icons/Edit';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MenuIcon from '@material-ui/icons/Menu';
import { useTranslation } from 'react-i18next';

export default function Responses() {  
  const {t} = useTranslation();

  const history = useHistory()
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const anchorEl = useRef<HTMLButtonElement>(null);
  const calculatorContext = useContext(CalculatorContext)
  const calculation = useObservableState(calculatorContext.calculation$, calculatorContext.getPreviousCalculation())
  const selected = useObservableState(calculatorContext.selectedCategories$, {"goods":false, "services":false})
  const status = useObservableState(calculatorContext.status$)
  const onChange = (checked: boolean, key: string) => {
    const newSelected = {
      ...selected,
      [key]: checked
    }
    calculatorContext.onSelectCategories(newSelected)
  }
  
  const goToLast = () =>{
    if(status?.last){      
      goToFootprint(status?.last)
    }
  }
  const goToFootprint = (section:string) =>{
    history.push(`/calculator/question/${section}`)
  }
  return (
    <div className="responses">      
      <Accordion className="controls-filter">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {t("footprint:filter")}          
        </AccordionSummary>
        <AccordionDetails>
          {calculation?.sections?.map((section, index) =>{            
            return <SectionSelection className="white" showCheckbox key={index} section={section} selected={selected[section.key] === undefined ? true : selected[section.key]} onChange={(checked) => onChange(checked, section.key)} />
          })}
        </AccordionDetails>
      </Accordion>
      <div className="table">
        {calculation &&
          <DashboardTable selected={selected} table={calculation.table} total={calculation.total} />
        }
      </div>      
      <footer className='nav'>
        <div  className="navigation-buttons">
          <Button className="nav-btn-prev" onClick={()=>{ goToLast()}}  variant="contained" color="secondary" disableElevation><ArrowBackIosIcon/></Button>
          
          <Button disabled={true} className="nav-btn-next" variant="contained" color="secondary" disableElevation><ArrowForwardIosIcon/></Button>
          <Button ref={anchorEl} className="nav-btn-menu" onClick={()=>setMenuOpen(true)}  variant="outlined" color="secondary" disableElevation><MenuIcon/> <span className='text'>{t('questions:Categories')}</span></Button>
        </div>
      </footer>
      <Popper open={menuOpen} anchorEl={anchorEl.current} role={undefined} transition disablePortal>          
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                    <MenuList autoFocusItem={menuOpen} id="menu-list-grow">
                      {status?.sections.map((section, index) => {
                        return (
                          <MenuItem key={index} onClick={() => goToFootprint(section.firstSlug)}>
                            {t("footprint:".concat(section.label))}
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
        </Popper>
    </div>
  )
}