
import { useObservableState } from 'observable-hooks';
import { useContext } from 'react';
// @ts-ignore
import Odometer from 'react-odometerjs';
import { Link } from 'react-router-dom';
import CalculatorContext from '../context/CalculatorContext';

import './FootprintScoreGraph.css'
import Tooltip from './Tooltip';
import { useTranslation } from 'react-i18next';

interface IFootprintScoreProps{
  score: number  
  label?: string
}
export default function FootprintScore({score,label}: IFootprintScoreProps) {
  const {t} = useTranslation();

  const calculatorContext = useContext(CalculatorContext)  
  const tooltips = useObservableState(calculatorContext.tooltips$)  
  return (
    <div className="footprint_score_graph">      
      <div className="footprint-type">
        <span>
          {t("footprint:household")}
        </span>
        {tooltips && tooltips['household'] &&
          <Tooltip id="household" title={tooltips['household'].title} text={tooltips['household'].text}/>
        }
      </div>
      <div className="score">
        <Link to="/calculator/footprint" className="footprint-link">          
          <Odometer duration={100}  value={score} format="(,ddd).dd" />
        </Link>
      </div>
      <div className="footprint-summary">
        {t("footprint:tons")}&nbsp;
        {tooltips && tooltips['tons'] &&
          <Tooltip id="tons" title={tooltips['tons'].title} text={tooltips['tons'].text}/>
        }
      </div>       
    </div>
  )
}